import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import "./CustomerDetails.scss";
import {
  WithData,
  WithAnagraficaClienteID,
} from "../../models/shared/CommonModels";
import { CredenzialiPortaleVenditaModel } from "../../models/CredenzialiPortaleVenditaModel";
import { fetchCustomerCredenzialiPortaleVenditaQueryKey } from "../../utils/QueryClient";
import {
  deleteCredenzialiPortaleVendita,
  fetchCustomerCredenzialiPortaleVendita,
} from "../../api/credenzialiportalevendita";
import SimpleDataTable from "../shared/SimpleDataTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import DeleteDialog from "../shared/DeleteDialog";
import PortaleVenditaForm from "./extra/PortaleVenditaForm";

interface WithCrPorVenRecord extends WithAnagraficaClienteID {
  record: CredenzialiPortaleVenditaModel;
  createFormOpen: boolean;
  toggleCreateFormOpen: () => void;
  setEnableCreateButton: (state: boolean) => void;
}

const CustomerCredenzialiPortaleVenditaTable: React.FC<WithCrPorVenRecord> = ({
  anagrafica_cliente_id,
  createFormOpen,
  toggleCreateFormOpen,
  setEnableCreateButton,
}) => {
  const queryClient = useQueryClient();

  const [credPortaleVendita, setCredPortaleVendita] = useState<
    CredenzialiPortaleVenditaModel[]
  >(undefined as any);
  const [selectedRowElement, setSelectedRowElement] =
    useState<CredenzialiPortaleVenditaModel>();

  const [portaleVenditaDataEditFormOpen, setPortaleVenditaDataEditFormOpen] =
    useState<boolean>(false);
  const togglePortaleVenditaDataEditFormOpen = () =>
    setPortaleVenditaDataEditFormOpen(!portaleVenditaDataEditFormOpen);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const query = useQuery({
    queryKey: [fetchCustomerCredenzialiPortaleVenditaQueryKey],
    queryFn: () =>
      fetchCustomerCredenzialiPortaleVendita(0, anagrafica_cliente_id),
    onSuccess: (getResponse) => {
      let data = getResponse.data.data;
      if (data.length >= 2) {
        setEnableCreateButton(false);
      } else {
        setEnableCreateButton(true);
      }
      setCredPortaleVendita(data);
    },
    refetchOnWindowFocus: false,
  });

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteCredenzialiPortaleVendita(x),
    onSuccess: () => {
      setSelectedRowElement(undefined);
      queryClient.invalidateQueries(
        fetchCustomerCredenzialiPortaleVenditaQueryKey
      );
    },
    onError: () => {},
  });

  if (
    query.isFetching ||
    query.isLoading ||
    query.isRefetching ||
    !credPortaleVendita
  )
    return <Skeleton animation="wave" />;

  const editOperations = (
    record: GridRenderCellParams<any, CredenzialiPortaleVenditaModel>
  ) => {
    setSelectedRowElement(record.value);
    togglePortaleVenditaDataEditFormOpen();
  };

  const deleteOperations = (
    record: GridRenderCellParams<any, CredenzialiPortaleVenditaModel>
  ) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  return (
    <>
      <SimpleDataTable<CredenzialiPortaleVenditaModel>
        columns={[
          {
            field: "nome",
            headerName: "Nome Portale",
            flex: 1,
          },
          {
            field: "link",
            headerName: "Link Portale",
            renderCell: (params) => (
              <Link to={`${params.value}`}>{params.value}</Link>
            ),
            flex: 1,
          },
          {
            field: "credenziali",
            headerName: "Credenziali Accesso",
            flex: 1,
          },
        ]}
        rows={
          credPortaleVendita !== undefined
            ? credPortaleVendita.map((d) => {
                return {
                  id: d.id,
                  nome: d.anagraficaportale.nome_portale,
                  link: d.anagraficaportale.link_portale,
                  credenziali: d.credenziali_di_accesso,
                  actions: d,
                  delete_action: d,
                } as unknown as CredenzialiPortaleVenditaModel;
              })
            : []
        }
        rowCount={credPortaleVendita?.length}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
      />

      {/* Add Portale Vendita */}
      {credPortaleVendita && (
        <PortaleVenditaForm
          initialData={
            {
              id_cliente: anagrafica_cliente_id,
              // id_anagrafica_portale: credPortaleVendita[0].id
            } as CredenzialiPortaleVenditaModel
          }
          open={createFormOpen}
          toggleOpen={toggleCreateFormOpen}
        />
      )}

      {/* Edit Portale Vendita */}
      {selectedRowElement && (
        <PortaleVenditaForm
          initialData={selectedRowElement}
          open={portaleVenditaDataEditFormOpen}
          toggleOpen={togglePortaleVenditaDataEditFormOpen}
          editEnabled={true}
        />
      )}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.anagraficaportale.nome_portale}
        onPositive={() => {
          toggleDeleteAlert();
          const x = {} as WithData;
          x.data = selectedRowElement?.id;
          deleteMutation.mutate(x);
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default CustomerCredenzialiPortaleVenditaTable;
