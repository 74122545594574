import { GridFilterModel } from "@mui/x-data-grid";
import { SortingModel } from "../../models/shared/SortingModel";
import { api_url } from "../../utils/ApiContext";
import { appendStringFilterToUri } from "../../utils/filtering";
import { BeneCondizioneModel } from "../../models/api/response/bene/BeneCondizioneModel";
import {
  FetchPaginatedApiModel,
  WithData,
  WithRecordID,
} from "../../models/shared/CommonModels";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../apiCommon";

const TAKE_LIMIT = 15;

const setQueryParameters = (
  filter: GridFilterModel | undefined,
  sort: SortingModel
) => {
  let uri = api_url.concat("/api/qualitabene?");

  filter?.items.forEach((item) => {
    uri = appendStringFilterToUri(item.field, "nome", item.value, uri);
    uri = appendStringFilterToUri(item.field, "descrizione", item.value, uri);
  });

  uri = uri.concat("&sort_field=", sort.field.toString());
  uri = uri.concat("&sort_direction=", sort.direction.toString());

  uri = uri.endsWith("?") ? uri.concat("skip=") : uri.concat("&skip=");

  return uri;
};

const getCondizione = (
  selectedId: number,
  page: number,
  sort: SortingModel,
  filter?: GridFilterModel
) => {
  let uri = setQueryParameters(filter, sort);

  let skip = 0;
  let take = TAKE_LIMIT;
  if (page > 0) skip = page * take;

  return getRequest<FetchPaginatedApiModel<BeneCondizioneModel[]>>(
    uri.concat(
      "&skip=",
      skip.toString(),
      "&take=",
      take.toString(),
      "&selectedId=",
      selectedId.toString()
    )
  );
};

const createCondizioneBene = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/qualitabene"), postdata.data);
};

const updateCondizione = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/qualitabene/", putdata.recordID.toString()),
    putdata.data
  );
};

const deleteCondizione = (id: number) => {
  return deleteRequest(api_url.concat("/api/qualitabene/", id.toString()));
};

export {
  getCondizione,
  createCondizioneBene,
  updateCondizione,
  deleteCondizione,
};
