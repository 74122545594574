import { WithData } from "../../models/shared/CommonModels";
import { api_url } from "../../utils/ApiContext";
import { getRequest, postRequest } from "../apiCommon";

const generateContractPreview = async (contractData: WithData) => {
  return postRequest(
    api_url.concat("/api/contratto/generate-preview"),
    contractData.data
  );
};

const getContractDocument = async (contractId: string) => {
  return getRequest<string>(
    api_url.concat("/api/contratto/" + contractId + "/sent-document")
  );
};

export { generateContractPreview, getContractDocument };
