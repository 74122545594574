import { GridFilterModel } from "@mui/x-data-grid";
import { BeneModelDetailed } from "../models/BeneModel";
import {
  FetchPaginatedApiModel,
  WithRecordID,
  WithData,
} from "../models/shared/CommonModels";
import { api_url } from "../utils/ApiContext";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";
import { SortingModel } from "../models/shared/SortingModel";
import {
  appendNumberFilterToUri,
  appendStringFilterToUri,
} from "../utils/filtering";

const TAKE_LIMIT = 15;

const setQueryParameters = (
  filter: GridFilterModel | undefined,
  sort: SortingModel,
  id_anagrafica_cliente: number
) => {
  let uri = api_url.concat("/api/bene?");

  filter?.items.forEach((item) => {
    uri = appendStringFilterToUri(item.field, "ubicazione", item.value, uri);
    uri = appendStringFilterToUri(
      item.field,
      "tipologia_bene",
      item.value,
      uri
    );
    uri = appendStringFilterToUri(item.field, "qualita_bene", item.value, uri);
    uri = appendStringFilterToUri(item.field, "brand_bene", item.value, uri);
    uri = appendStringFilterToUri(
      item.field,
      "bene_contratto_titolo",
      item.value,
      uri
    );
    uri = appendStringFilterToUri(
      item.field,
      "bene_condizione_nome",
      item.value,
      uri
    );
    uri = appendStringFilterToUri(
      item.field,
      "codice_fiscale",
      item.value,
      uri
    );
  });

  if (id_anagrafica_cliente !== -1) {
    uri = appendNumberFilterToUri(
      "id_cliente",
      "id_cliente",
      id_anagrafica_cliente,
      uri
    );
  }

  uri = uri.concat("&sort_field=", sort.field.toString());
  uri = uri.concat("&sort_direction=", sort.direction.toString());

  uri = uri.endsWith("?") ? uri.concat("skip=") : uri.concat("&skip=");

  return uri;
};

const fetchSelectBeni = (
  page: number,
  filter: GridFilterModel | undefined,
  sort: SortingModel,
  id_anagrafica_cliente: number
) => {
  let uri = setQueryParameters(filter, sort, id_anagrafica_cliente);
  return fetchBeni(page, uri);
};

const fetchBeni = (
  page: number,
  uri: string = api_url.concat("/api/bene?skip=")
) => {
  let skip = 0;
  let take = TAKE_LIMIT;
  if (page > 0) skip = page * take;
  return getRequest<FetchPaginatedApiModel<BeneModelDetailed[]>>(
    uri.concat(skip.toString(), "&take=", take.toString())
  );
};

const createBene = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/bene"), postdata.data);
};

const fetchBene = (getData: WithData) => {
  return getRequest<BeneModelDetailed>(
    api_url.concat("/api/bene/", getData.data as string)
  );
};

const editBene = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/bene/", putdata.recordID.toString()),
    putdata.data
  );
};

const deleteBene = (postdata: WithData) => {
  return deleteRequest(api_url.concat("/api/bene/", postdata.data as string));
};

export { deleteBene, fetchSelectBeni, createBene, fetchBene, editBene };
