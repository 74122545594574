import { CustomerDocumentTypeModel } from "../../models/api/customer/document/customer-document-type-model";
import { api_url } from "../../utils/ApiContext";
import { getRequest } from "../apiCommon";

const fetchCustomerDocumentTypes = () => {
  return getRequest<CustomerDocumentTypeModel[]>(
    api_url.concat("/api/customer-document-type")
  );
};

export { fetchCustomerDocumentTypes };
