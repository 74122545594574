import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

interface AlertDialogProps {
  open: boolean;
  onClose: () => void;
  onPositive: () => void;
  onNegative: () => void;
  customMessage: string;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  onClose,
  onPositive,
  onNegative,
  customMessage,
}) => {
  return (
    <>
      {/* Delete */}
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{customMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={onNegative}>No</Button>
          <Button onClick={onPositive} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
