import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { login as apiLogin } from "../../api/auth";
import { Navigate } from "react-router-dom";
import { useMutation } from "react-query";
import { LoginModel } from "../../models/AuthModel";
import ErrorMessageAlertComponent from "../../components/errors/ErrorMessageAlertComponent";
import logo from "../../images/open.svg";
import {
  getAccessToken,
  setAccessToken,
  setRefreshAccessToken,
} from "../../utils/tokenStore";

const Copyright: React.FC<any> = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"OpenCloset - "}
      {"Copyright © "}
      {new Date().getFullYear()}
    </Typography>
  );
};

const LoginPage: React.FC<{}> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const hideError = () => setErrorMessage("");

  const mutation = useMutation({
    mutationFn: (x: LoginModel) => apiLogin(x),
    onSuccess: (postResponse) => {
      setAccessToken(postResponse.data.token);
      setRefreshAccessToken(postResponse.data.refresh_token);
    },
    onError: (error: any) => {
      if (error.response.data.message !== undefined)
        setErrorMessage(error.response.data.message);
      else
        setErrorMessage(
          "Errore non previsto, riprovare o richiedere assistenza"
        );
    },
  });

  if (mutation.isSuccess && getAccessToken().length > 0)
    return <Navigate to="/home" replace />;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            marginBottom: "10pt",
          }}
          alt="OpenCloset"
          src={logo}
        />
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              const request = {} as LoginModel;
              request.email = email;
              request.password = password;
              mutation.mutate(request);
            }}
          >
            Sign In
          </Button>
          <ErrorMessageAlertComponent
            visible={errorMessage.length > 0}
            hide={hideError}
            error_message={errorMessage}
          />
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 4, mb: 4 }} />
    </Container>
  );
};

export default LoginPage;
