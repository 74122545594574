import { api_url } from "../../utils/ApiContext";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../apiCommon";
import { WithData, WithRecordID } from "../../models/shared/CommonModels";
import { BankAccount } from "../../models/api/bank-account/bank-account-model";

const apiPrefix: string = "/api/bank-account";

const fetchBankAccounts = (idCliente: number) => {
  return getRequest<BankAccount[]>(
    api_url.concat(apiPrefix + "?id_customer=", idCliente.toString())
  );
};

const fetchBankAccount = (id: number) => {
  return getRequest<BankAccount>(
    api_url.concat(apiPrefix + "/", id.toString())
  );
};

const createBankAccount = (postData: WithData) => {
  return postRequest(api_url.concat(apiPrefix), postData.data);
};

const editBankAccount = (putData: WithRecordID) => {
  return putRequest(
    api_url.concat(apiPrefix + "/", putData.recordID.toString()),
    putData.data
  );
};

const deleteBankAccount = (postData: WithData) => {
  return deleteRequest(
    api_url.concat(apiPrefix + "/", postData.data as string)
  );
};

export {
  fetchBankAccounts,
  fetchBankAccount,
  createBankAccount,
  editBankAccount,
  deleteBankAccount,
};
