import { Skeleton } from "@mui/material";
import {
  WithAnagraficaClienteID,
  WithData,
} from "../../models/shared/CommonModels";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import SimpleDataTable from "../shared/SimpleDataTable";
import DeleteDialog from "../shared/DeleteDialog";
import queryClient, {
  fetchCustomerBankAccountsQueryKey,
} from "../../utils/QueryClient";
import { BankAccount } from "../../models/api/bank-account/bank-account-model";
import {
  deleteBankAccount,
  fetchBankAccounts,
} from "../../api/bank-account/bank-account-client";
import BankAccountForm from "./extra/BankAccountForm";

interface WithBankAccountRecord extends WithAnagraficaClienteID {
  record: BankAccount;
  createFormOpen: boolean;
  toggleCreateFormOpen: () => void;
}

const BankAccountTable: React.FC<WithBankAccountRecord> = ({
  anagrafica_cliente_id,
  createFormOpen,
  toggleCreateFormOpen,
}) => {
  const [data, setData] = useState<BankAccount[]>(undefined as any);
  const [selectedRowElement, setSelectedRowElement] = useState<BankAccount>();

  const [dataEditFormOpen, setDataEditFormOpen] = useState<boolean>(false);
  const toggleDataEditFormOpen = () => setDataEditFormOpen(!dataEditFormOpen);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteBankAccount(x),
    onSuccess: () => {
      setSelectedRowElement(undefined);
      queryClient.invalidateQueries(fetchCustomerBankAccountsQueryKey);
    },
    onError: () => {},
  });

  const query = useQuery({
    queryKey: [fetchCustomerBankAccountsQueryKey],
    queryFn: () => fetchBankAccounts(anagrafica_cliente_id),
    onSuccess: (getResponse) => {
      setData(getResponse.data);
    },
    refetchOnWindowFocus: false,
  });

  const editOperations = (record: GridRenderCellParams<any, BankAccount>) => {
    setSelectedRowElement(record.value);
    toggleDataEditFormOpen();
  };

  const deleteOperations = (record: GridRenderCellParams<any, BankAccount>) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  // TODO: verify if (query.isError) return (<Navigate to="/home" replace />)

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  return (
    <>
      <SimpleDataTable<BankAccount>
        columns={[
          {
            field: "bank_name",
            headerName: "Banca (o PSP)",
            flex: 1,
          },
          {
            field: "iban",
            headerName: "IBAN",
            flex: 1,
          },
          {
            field: "swift",
            headerName: "Swift",
            flex: 1,
          },
          {
            field: "account_holder",
            headerName: "Intestatario/a",
            flex: 1,
          },
          {
            field: "preferred",
            headerName: "Preferito",
            type: "boolean",
            sortable: false,
            flex: 1,
          },
        ]}
        rows={
          data !== undefined
            ? data.map((d) => {
                return {
                  id: d.id,
                  bank_name: d.bank_name,
                  iban: d.iban,
                  swift: d.swift,
                  account_holder: d.account_holder,
                  preferred: d.preferred,
                  actions: d,
                  delete_action: d,
                } as unknown as BankAccount;
              })
            : []
        }
        rowCount={data.length}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
      />

      {/* Add */}
      <BankAccountForm
        initialData={{ id_customer: anagrafica_cliente_id } as BankAccount}
        open={createFormOpen}
        toggleOpen={toggleCreateFormOpen}
      />

      {/* Edit */}
      {selectedRowElement && (
        <BankAccountForm
          initialData={selectedRowElement}
          open={dataEditFormOpen}
          toggleOpen={toggleDataEditFormOpen}
          editEnabled={true}
        />
      )}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.iban}
        onPositive={() => {
          toggleDeleteAlert();
          const x = {} as WithData;
          x.data = selectedRowElement?.id;
          deleteMutation.mutate(x);
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default BankAccountTable;
