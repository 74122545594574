import { Skeleton } from "@mui/material";
import { CustomerDocument } from "../../models/api/customer/document/customer-document-model";
import {
  WithAnagraficaClienteID,
  WithData,
} from "../../models/shared/CommonModels";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import SimpleDataTable from "../shared/SimpleDataTable";
import DeleteDialog from "../shared/DeleteDialog";
import CustomerDocumentForm from "./extra/CustomerDocumentForm";
import queryClient, {
  fetchCustomerDocumentsQueryKey,
} from "../../utils/QueryClient";
import {
  deleteCustomerDocument,
  fetchCustomerDocuments,
} from "../../api/customer-document/customer-document-client";

interface WithCustomerDocumentRecord extends WithAnagraficaClienteID {
  record: CustomerDocument;
  createFormOpen: boolean;
  toggleCreateFormOpen: () => void;
}

const CustomerDocumentTable: React.FC<WithCustomerDocumentRecord> = ({
  anagrafica_cliente_id,
  createFormOpen,
  toggleCreateFormOpen,
}) => {
  const [data, setData] = useState<CustomerDocument[]>(undefined as any);
  const [selectedRowElement, setSelectedRowElement] =
    useState<CustomerDocument>();

  const [dataEditFormOpen, setDataEditFormOpen] = useState<boolean>(false);
  const toggleDataEditFormOpen = () => setDataEditFormOpen(!dataEditFormOpen);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteCustomerDocument(x),
    onSuccess: () => {
      setSelectedRowElement(undefined);
      queryClient.invalidateQueries(fetchCustomerDocumentsQueryKey);
    },
    onError: () => {},
  });

  const query = useQuery({
    queryKey: [fetchCustomerDocumentsQueryKey],
    queryFn: () => fetchCustomerDocuments(anagrafica_cliente_id),
    onSuccess: (getResponse) => {
      setData(getResponse.data);
    },
    refetchOnWindowFocus: false,
  });

  const editOperations = (
    record: GridRenderCellParams<any, CustomerDocument>
  ) => {
    setSelectedRowElement(record.value);
    toggleDataEditFormOpen();
  };

  const deleteOperations = (
    record: GridRenderCellParams<any, CustomerDocument>
  ) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  // TODO: verify if (query.isError) return (<Navigate to="/home" replace />)

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  return (
    <>
      <SimpleDataTable<CustomerDocument>
        columns={[
          {
            field: "type",
            headerName: "Tipo",
            flex: 1,
          },
          {
            field: "document_number",
            headerName: "Numero",
            flex: 1,
          },
          {
            field: "released_by",
            headerName: "Rilasciato da",
            flex: 1,
          },
          {
            field: "expires_at",
            headerName: "Scadenza",
            flex: 1,
          },
          {
            field: "preferred",
            headerName: "Preferito",
            type: "boolean",
            sortable: false,
            flex: 1,
          },
        ]}
        rows={
          data !== undefined
            ? data.map((d) => {
                return {
                  id: d.id,
                  type: d.id_document_type,
                  document_number: d.document_number,
                  released_by: d.released_by,
                  expires_at: d.expires_at,
                  preferred: d.preferred,
                  actions: d,
                  delete_action: d,
                } as unknown as CustomerDocument;
              })
            : []
        }
        rowCount={data.length}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
      />

      {/* Add */}
      <CustomerDocumentForm
        initialData={{ id_customer: anagrafica_cliente_id } as CustomerDocument}
        open={createFormOpen}
        toggleOpen={toggleCreateFormOpen}
      />

      {/* Edit */}
      {selectedRowElement && (
        <CustomerDocumentForm
          initialData={selectedRowElement}
          open={dataEditFormOpen}
          toggleOpen={toggleDataEditFormOpen}
          editEnabled={true}
        />
      )}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.document_number}
        onPositive={() => {
          toggleDeleteAlert();
          const x = {} as WithData;
          x.data = selectedRowElement?.id;
          deleteMutation.mutate(x);
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default CustomerDocumentTable;
