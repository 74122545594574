import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  WithCUToggleCommandsAndData,
  WithData,
} from "../../models/shared/CommonModels";
import { REQUIRED_HELPER_TEXT } from "../../utils/form";
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent";
import {
  ConfigurationEmail,
  ConfigurationEmailPostModel,
  ConfigurationEmailRequest,
} from "../../models/api/configuration/configuration-email";
import { fromAxiosErrorToMessage } from "../../utils/crud";
import { AxiosError } from "axios";
import { fetchEmailConfQueryKey } from "../../utils/QueryClient";
import { LoadingButton } from "@mui/lab";
import {
  sendTestEmail,
  updateEmailConf,
} from "../../api/configuration/configuration-email";
import TestEmailForm from "./TestEmailForm";

const EmailConfigurationForm: React.FC<
  WithCUToggleCommandsAndData<ConfigurationEmail>
> = ({ initialData }) => {
  const queryClient = useQueryClient();

  const [hideError, setHideError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [driver, setDriver] = useState<string>(initialData.driver);
  const [host, setHost] = useState<string>(initialData.host);
  const [port, setPort] = useState<number>(initialData.port);
  const [fromAddress, setFromAddress] = useState<string>(
    initialData.from_address
  );
  const [fromName, setFromName] = useState<string>(initialData.from_name);
  const [encryption, setEncryption] = useState<string>(initialData.encryption);
  const [username, setUsername] = useState<string>(initialData.username);
  const [credential, setCredential] = useState<string>();

  const [testEmailFormOpen, setTestEmailFormOpen] = useState<boolean>(false);

  const updateMutation = useMutation({
    mutationFn: (x: WithData) => updateEmailConf(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchEmailConfQueryKey);
      setLoading(false);
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
      setLoading(false);
    },
  });

  // if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  const handleSubmit = () => {
    setLoading(true);

    if (
      driver === undefined ||
      host === undefined ||
      port === undefined ||
      fromAddress === undefined ||
      fromName === undefined ||
      encryption === undefined ||
      username === undefined
    ) {
      setErrorMessage("Inserisci i campi obbligatori");
      setHideError(false);
      setLoading(false);
      return;
    } else {
      setHideError(true);
    }

    const emailPost = {} as ConfigurationEmailPostModel;
    emailPost.driver = driver;
    emailPost.host = host;
    emailPost.port = port;
    emailPost.from_address = fromAddress;
    emailPost.from_name = fromName;
    emailPost.encryption = encryption;
    emailPost.username = username;

    if (credential !== undefined) {
      emailPost.credential = credential;
    }

    const postData = {} as WithData;
    const body = {} as ConfigurationEmailRequest;
    body.email_conf = emailPost;
    postData.data = body;

    updateMutation.mutate(postData);
  };

  const handleEmailTest = () => {
    setTestEmailFormOpen(true);
  };

  return (
    <>
      <Box style={{ width: 500 }}>
        <ErrorMessageAlertComponent
          error_message={errorMessage}
          hide={() => {
            setHideError(true);
          }}
          visible={!hideError}
        />

        <Typography sx={{ fontSize: "12px", color: "grey" }}>
          {REQUIRED_HELPER_TEXT}
        </Typography>

        <TextField
          margin="normal"
          required
          fullWidth
          label="Driver"
          name="driver"
          size="small"
          value={driver}
          onChange={(e) => setDriver(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Host"
          name="host"
          size="small"
          value={host}
          onChange={(e) => setHost(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Port"
          name="port"
          size="small"
          type="number"
          value={port}
          onChange={(e) => setPort(e.target.value as unknown as number)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="From Address"
          name="from_address"
          size="small"
          value={fromAddress}
          onChange={(e) => setFromAddress(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="From Name"
          name="from_name"
          size="small"
          value={fromName}
          onChange={(e) => setFromName(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Encryption"
          name="encryption"
          size="small"
          value={encryption}
          onChange={(e) => setEncryption(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Username"
          name="username"
          size="small"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Credential"
          name="credential"
          size="small"
          value={credential}
          onChange={(e) => setCredential(e.target.value)}
        />
      </Box>
      <Box sx={{ justifyContent: "flex-end" }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Salva
        </LoadingButton>
        <LoadingButton
          sx={{ marginLeft: "5pt" }}
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => handleEmailTest()}
        >
          Invio di Prova
        </LoadingButton>
      </Box>

      {/* Mini form for test email insertion */}
      <TestEmailForm
        initialData=""
        open={testEmailFormOpen}
        toggleOpen={() => setTestEmailFormOpen(!testEmailFormOpen)}
      />
    </>
  );
};

export default EmailConfigurationForm;
