import { QueryClient } from "react-query";

const queryClient = new QueryClient();

// Each value must be unique
const fetchAllFinalConditionsQueryKey: string =
  "fetchAllFinalConditionsQueryKey";
const fetchIndirizziQueryKey: string = "FetchCustomerIndirizzi";
const fetchSingleIndirizzoQueryKey: string = "fetchSingleIndirizzo";
const fetchCustomerDocumentsQueryKey: string = "FetchCustomerDocuments";
const fetchSingleCustomerDocumentQueryKey: string =
  "fetchSingleCustomerDocuments";
const fetchCustomerBankAccountsQueryKey: string = "FetchCustomerBankAccounts";
const fetchSingleCustomerBankAccountQueryKey: string =
  "FetchSingleCustomerBankAccounts";
const fetchEmailsQueryKey: string = "FetchCustomerEmails";
const fetchSingleEmailQueryKey: string = "fetchCustomerEmail";
const fetchEmailConfQueryKey: string = "fetchEmailConf";
const fetchTelefoniQueryKey: string = "FetchCustomerTelefoni";
const fetchSingleTelefonoQueryKey: string = "fetchCustomerTelefono";
const fetchPFsQueryKey: string = "fetchAllPF";
const fetchPFsQByKeyQueryKey: string = "fetchPFsBySearchKey";
const fetchPFsTableQueryKey: string = "fetchAllPFSelect";
const fetchPGsQueryKey: string = "fetchAllPG";
const fetchPFQueryKey: string = "FetchPF";
const fetchPGQueryKey: string = "FetchPG";
const fetchCommissioniQueryKey: string = "FetchCommissioniTable";
const fetchBeniQueryKey: string = "FetchBeniTable";
const fetchCustomerDocumentTypeSelectQueryKey: string =
  "FetchCustomerDocumentTypeSelect";
const fetchTipologiaContrattoSelectQueryKey: string =
  "FetchTipologiaSelectContratto";
const fetchStatoContrattoSelectQueryKey: string = "FetchStatoSelectContratto";
const fetchCollezioneBeneSelectQueryKey: string = "FetchCollezioneBene";
const fetchMaterialeBeneSelectQueryKey: string = "FetchMaterialeBene";
const fetchColoreBeneSelectQueryKey: string = "FetchColoreBene";
const fetchBrandBeneSelectQueryKey: string = "FetchBrandBene";
const fetchQualitaBeneSelectQueryKey: string = "FetchQualitaBene";
const fetchContrattoQueryKey: string = "FetchContrattoDetailed";
const fetchTipologiaBeneSelectQueryKey: string = "FetchTipologiaSelectBene";
const fetchDetailedTipologiaBeneQuery: string = "FetchDetailedTipologiaBene";
const fetchSottoTipologiaBeneSelectQueryKey: string =
  "FetchSottoTipologiaSelectBene";
const fetchCustomerCredenzialiPortaleVenditaQueryKey: string =
  "FetchCustomerCredenzialiPortaleVendita";
const fetchCustomerCredenzialePortaleVenditaQueryKey: string =
  "fetchCustomerCredenzialePortaleVendita";
const fetchDetailedBeneQuery: string = "FetchDetailedBene";
const fetchPossibleContrattoBeneAssociationQueryKey: string =
  "FetchPossibleContrattoBeneAssociation";
const fetchContrattoPossibiliAssociazioni: string =
  "FetchBenePossibiliContrattiList";
const fetchBeneContrattoPortaleTableQueryKey: string =
  "fetchBeneContrattoPortaleTable";
const fetchAvailableCredenzialiPortaleVenditaForBeneContrattoQueryKey: string =
  "fetchAvailableCredenzialiPortaleVenditaForBeneContratto";
const fetchAnagraficaPortaleQueryKey: string = "fetchAnagraficaPortaleQueryKey";

export default queryClient;

export {
  fetchAllFinalConditionsQueryKey,
  fetchAnagraficaPortaleQueryKey,
  fetchAvailableCredenzialiPortaleVenditaForBeneContrattoQueryKey,
  fetchBeneContrattoPortaleTableQueryKey,
  fetchContrattoPossibiliAssociazioni,
  fetchQualitaBeneSelectQueryKey,
  fetchBrandBeneSelectQueryKey,
  fetchMaterialeBeneSelectQueryKey,
  fetchColoreBeneSelectQueryKey,
  fetchCollezioneBeneSelectQueryKey,
  fetchIndirizziQueryKey,
  fetchSingleIndirizzoQueryKey,
  fetchCustomerDocumentsQueryKey,
  fetchSingleCustomerDocumentQueryKey,
  fetchSingleEmailQueryKey,
  fetchEmailsQueryKey,
  fetchTelefoniQueryKey,
  fetchSingleTelefonoQueryKey,
  fetchPFsQueryKey,
  fetchPFsQByKeyQueryKey,
  fetchPGsQueryKey,
  fetchPFQueryKey,
  fetchPGQueryKey,
  fetchCommissioniQueryKey,
  fetchPFsTableQueryKey,
  fetchTipologiaContrattoSelectQueryKey,
  fetchStatoContrattoSelectQueryKey,
  fetchContrattoQueryKey,
  fetchBeniQueryKey,
  fetchTipologiaBeneSelectQueryKey,
  fetchSottoTipologiaBeneSelectQueryKey,
  fetchCustomerCredenzialiPortaleVenditaQueryKey,
  fetchCustomerCredenzialePortaleVenditaQueryKey,
  fetchDetailedBeneQuery,
  fetchPossibleContrattoBeneAssociationQueryKey,
  fetchDetailedTipologiaBeneQuery,
  fetchCustomerDocumentTypeSelectQueryKey,
  fetchCustomerBankAccountsQueryKey,
  fetchSingleCustomerBankAccountQueryKey,
  fetchEmailConfQueryKey,
};
