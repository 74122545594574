import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from "@mui/material";
import SectionTitle from "../../components/shared/SectionTitle";
import SideBar from "../../components/sidebar/Sidebar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmailConfigurationForm from "../../components/conf/EmailConfigurationForm";
import { AxiosError } from "axios";
import { getEmailConf } from "../../api/configuration/configuration-email";
import { useQuery } from "react-query";
import { fetchEmailConfQueryKey } from "../../utils/QueryClient";
import { ConfigurationEmail } from "../../models/api/configuration/configuration-email";
// import { fromAxiosErrorToMessage } from "../../utils/crud";
import { useState } from "react";

const ConfigurationPage: React.FC<{}> = () => {
  // const [hideError, setHideError] = useState<boolean>(true);
  // const [errorMessage, setErrorMessage] = useState<string>("");

  const [emailConf, setEmailConf] = useState<ConfigurationEmail>();

  const query = useQuery({
    queryKey: [fetchEmailConfQueryKey],
    queryFn: () => getEmailConf(),
    enabled: true,
    onSuccess: (getResponse) => {
      setEmailConf(getResponse.data);
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      // setHideError(false);
      // setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  return (
    <>
      <div className="home">
        <SideBar />
        <div className="homeContainer">
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle title="OpenCloset - Mail" />
              </AccordionSummary>
              <AccordionDetails>
                <EmailConfigurationForm
                  initialData={emailConf!}
                  open={true}
                  toggleOpen={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ConfigurationPage;
