import React from "react";
import "./Landing.scss";
import logo from "../../images/open.svg";
import { Pages } from "../../utils/pages";
import { Link } from "react-router-dom";

const LandingPage: React.FC<{}> = () => {
  return (
    <div className="landing-page">
      <img
        src="https://images.pexels.com/photos/5865240/pexels-photo-5865240.jpeg"
        alt="Clothes Shop"
        className="background-image"
      />
      <Link className="admin-link" to={Pages.HOME}>
        Admin Section
      </Link>
      <img src={logo} alt="Main Logo" className="main-logo" />
    </div>
  );
};

export default LandingPage;
