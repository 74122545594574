import { Email } from "../models/EmailModel";
import { api_url } from "../utils/ApiContext";
import { WithRecordID, WithData } from "../models/shared/CommonModels";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";

const fetchCustomerEmails = (id_cliente: number) => {
  return getRequest<Email[]>(
    api_url.concat("/api/emailcliente?id_cliente=", id_cliente.toString())
  );
};

const fetchEmail = (id: number) => {
  return getRequest<Email>(api_url.concat("/api/emailcliente/", id.toString()));
};

const createEmail = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/emailcliente"), postdata.data);
};

const deleteEmail = (postdata: WithData) => {
  return deleteRequest(
    api_url.concat("/api/emailcliente/", postdata.data as string)
  );
};

const editEmail = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/emailcliente/", putdata.recordID.toString()),
    putdata.data
  );
};

export { fetchCustomerEmails, fetchEmail, createEmail, deleteEmail, editEmail };
