import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteContratto, fetchSelectContratti } from "../../api/contratto";
import {
  fetchNomeDescrizioneForEntity,
  statoContrattoUri,
  tipologiaContrattoUri,
} from "../../api/statotipologia";
import {
  FetchPaginatedApiModel,
  WithAnagraficaClienteID,
  WithFabObj,
  WithData,
  WithRowOperations,
} from "../../models/shared/CommonModels";
import { ContrattoTableModel } from "../../models/ContrattoModel";
import { NomeDescrizione } from "../../models/StatoTipologiaModel";
import {
  fetchStatoContrattoSelectQueryKey,
  fetchTipologiaContrattoSelectQueryKey,
  fetchCommissioniQueryKey,
} from "../../utils/QueryClient";
import { SortingModel } from "../../models/shared/SortingModel";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { castToBackendSort } from "../../utils/sorting";
import DeleteDialog from "../shared/DeleteDialog";
import FilteredDataTable from "../shared/FilteredDataTable";
import ContrattoFormComponent from "./ContrattoFormComponent";
import ContractDocumentPreview from "./ContractDocumentPreview";
import { getContractDocument } from "../../api/contract/document";

interface ContrattiTableComponentProps
  extends WithAnagraficaClienteID,
    WithFabObj {
  enableCreate: boolean;
}

const ContrattiTableComponent: React.FC<ContrattiTableComponentProps> = ({
  anagrafica_cliente_id,
  fabVisible,
  alterFabVisibility,
  enableCreate,
}) => {
  const navigator = useNavigate();
  const queryClient = useQueryClient();

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const [documentPreviewOpen, setDocumentPreviewOpen] = useState(false);
  const toggleDocumentPreviewOpen = () =>
    setDocumentPreviewOpen(!documentPreviewOpen);
  const [base64ContractDocumentPreview, setBase64ContractDocumentPreview] =
    useState<string>("");

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  // TODO: verify
  const [contratti, setContratti] = useState<
    FetchPaginatedApiModel<ContrattoTableModel[]>
  >(undefined as any);
  const [selectedRowElement, setSelectedRowElement] =
    useState<ContrattoTableModel>();

  const [filters, setFilters] = useState<GridFilterModel | undefined>();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [statoContrattoRecords, setStatoContrattoRecords] = useState<
    NomeDescrizione[]
  >([]);
  const [tipologiaContrattoRecords, setTipologiaContrattoRecords] = useState<
    NomeDescrizione[]
  >([]);

  const [errorMessage, setErrorMessage] = useState("");
  const hideError = () => setErrorMessage("");

  // stato contratto query
  useQuery({
    queryKey: [fetchStatoContrattoSelectQueryKey],
    queryFn: () =>
      fetchNomeDescrizioneForEntity(statoContrattoUri, false, true),
    onSuccess: (getResponse) => {
      if (statoContrattoRecords.length <= 0) {
        let data = getResponse.data;
        // data.unshift({
        //   id: -1,
        //   nome: "Nessun Filtro",
        // });
        setStatoContrattoRecords(data);
      }
    },
    refetchOnWindowFocus: false,
  });

  // tipologia contratto query
  useQuery({
    queryKey: [fetchTipologiaContrattoSelectQueryKey],
    queryFn: () => fetchNomeDescrizioneForEntity(tipologiaContrattoUri),
    onSuccess: (getResponse) => {
      if (tipologiaContrattoRecords.length <= 0) {
        let data = getResponse.data;
        // data.unshift({
        //   id: -1,
        //   nome: "Nessun Filtro",
        // });
        setTipologiaContrattoRecords(data);
      }
    },
    refetchOnWindowFocus: false,
  });

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteContratto(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCommissioniQueryKey);
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined)
        setErrorMessage(e.response.data.message);
      else
        setErrorMessage(
          "Errore non previsto, riprovare o richiedere assistenza"
        );
    },
  });

  // query
  useQuery(
    [
      fetchCommissioniQueryKey,
      paginationModel.page,
      filters,
      sortBy,
      sortDirection,
    ],
    () => {
      const sor = {} as SortingModel;
      sor.field = sortBy || "id";
      sor.direction = sortDirection || "DESC";
      return fetchSelectContratti(
        paginationModel.page,
        filters,
        sor,
        anagrafica_cliente_id
      );
    },
    {
      keepPreviousData: true,
      onSuccess: (getResponse) => {
        setContratti(getResponse.data);
      },
      refetchOnWindowFocus: false,
    }
  );

  // TODO: verify if (query.isError || statoContrattoQuery.isError || tipologiaContrattoquery.isError) return (<Navigate to="/home" replace />)

  const detailsOperations = (
    record: GridRenderCellParams<any, ContrattoTableModel>
  ) => {
    if (record.id !== undefined) {
      let model = (record as any).actions as ContrattoTableModel;
      navigator("/contratto/".concat(model.id.toString()));
    }
  };

  const deleteOperations = (
    record: GridRenderCellParams<any, ContrattoTableModel>
  ) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  const documentDetailOperations = async (
    record: GridRenderCellParams<any, ContrattoTableModel>
  ) => {
    // TODO: sarebbe bello saperlo riga per riga
    if (record.value?.id_contract_document === undefined) {
      alert("nessuna preview");
    }

    toggleDocumentPreviewOpen();

    let contractDocument = await getContractDocument(
      record.value?.id.toString() || ""
    );

    setBase64ContractDocumentPreview(contractDocument.data);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Contratto",
      flex: 1,
    },
    {
      field: "titolo",
      headerName: "Titolo",
      flex: 1,
    },
    // {
    //   field: "quantita",
    //   headerName: "Quantità di Articoli",
    //   sortable: false,
    //   flex: 1,
    // },
    // {
    //   field: "importo",
    //   headerName: "Importo del Contratto",
    //   sortable: false,
    //   flex: 1,
    // },
    {
      field: "stato",
      headerName: "Stato del Contratto",
      sortable: false,
      flex: 1,
    },
    {
      field: "tipologia",
      headerName: "Tipologia del Contratto",
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <>
      <>
        <FilteredDataTable<ContrattoTableModel, WithRowOperations>
          loading={contratti === undefined}
          detailsOperations={detailsOperations}
          deleteOperations={deleteOperations}
          documentDetailOperations={documentDetailOperations}
          rows={
            contratti?.data !== undefined
              ? contratti.data.map((d) => {
                  return {
                    id: d.id,
                    titolo: d.titolo,
                    // quantita: d.quantita,
                    // importo: d.importo + " €",
                    stato: d.statocontratto.nome,
                    tipologia: d.tipologiacontratto.nome,
                    documento: d.id_contract_document,
                    actions: d,
                    delete_action: d,
                    hasDetails: true,
                    hasEdit: true,
                    hasDelete: true,
                    hasContractDetail: d.id_contract_document !== null,
                  };
                })
              : []
          }
          rowCount={contratti?.count}
          onPaginationModelChange={setPaginationModel}
          columns={columns}
          errorMessage={errorMessage}
          hideError={hideError}
          filters={filters}
          sortModel={sortModel}
          onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
          onSortChange={(newSortModel) => {
            newSortModel.forEach((item) => {
              if (item !== undefined) {
                setSortBy(item.field);
                setSortDirection(castToBackendSort(item.sort));
              }
            });

            setSortModel(newSortModel);
          }}
        />

        {/* Create */}
        {
          <ContrattoFormComponent
            initialData={
              { id_cliente: anagrafica_cliente_id } as ContrattoTableModel
            }
            open={enableCreate ? !fabVisible : false}
            toggleOpen={() => {}}
            onClose={() => alterFabVisibility(true)}
            editEnabled={false}
          />
        }

        {/* Contract Document PDF Preview */}
        <ContractDocumentPreview
          initialData={{ base64ContractPdf: base64ContractDocumentPreview }}
          readonly={true}
          open={documentPreviewOpen}
          toggleOpen={toggleDocumentPreviewOpen}
        />

        {/* Delete */}
        <DeleteDialog
          open={deleteAlertOpen}
          onClose={toggleDeleteAlert}
          identifier={selectedRowElement?.id}
          onPositive={() => {
            toggleDeleteAlert();
            const x = {} as WithData;
            x.data = selectedRowElement?.id;
            deleteMutation.mutate(x);
          }}
          onNegative={toggleDeleteAlert}
        />
      </>
    </>
  );
};

export default ContrattiTableComponent;
