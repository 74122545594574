import { NomeDescrizione } from "../models/StatoTipologiaModel";
import { api_url } from "../utils/ApiContext";
import { WithRecordID, WithData } from "../models/shared/CommonModels";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";

const tipologiaBeneUri: string = "tipologiabene";
const sottoTipologiaBeneUri: string = "sottotipologiabene";
const tipologiaContrattoUri: string = "tipologiacontratto";
const statoContrattoUri: string = "statocontratto";
const qualitaBeneUri: string = "qualitabene";
const materialeBeneUri: string = "materialebene";
const coloreBeneUri: string = "colorebene";
const collezioneBeneUri: string = "collezionebene";
const brandBeneUri: string = "brandbene";

export const fetchNomeDescrizioneForEntity = (
  baseUri: string,
  crezione: boolean = false,
  ricerca: boolean = false,
  id_contratto: number = -1
) => {
  let uri: string = api_url.concat("/api/", baseUri);
  if (crezione) uri = uri.concat("?creazione=true");
  else if (ricerca) uri = uri.concat("?ricerca=true");
  else if (id_contratto > -1)
    uri = uri.concat("?id_contratto=", id_contratto.toString());
  return getRequest<NomeDescrizione[]>(uri);
};

export const createNomeDescrizione = (postdata: WithData, baseUri: string) => {
  return postRequest(api_url.concat("/api/", baseUri), postdata.data);
};

export const deleteNomeDescrizione = (postdata: WithData, baseUri: string) => {
  return deleteRequest(
    api_url.concat("/api/", baseUri, "/", postdata.data as string)
  );
};

export const editNomeDescrizione = (putdata: WithRecordID, baseUri: string) => {
  return putRequest(
    api_url.concat("/api/", baseUri, "/", putdata.recordID.toString()),
    putdata.data
  );
};

export {
  tipologiaBeneUri,
  sottoTipologiaBeneUri,
  tipologiaContrattoUri,
  statoContrattoUri,
  qualitaBeneUri,
  materialeBeneUri,
  coloreBeneUri,
  collezioneBeneUri,
  brandBeneUri,
};
