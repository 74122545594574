import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  WithCUToggleCommandsAndData,
  WithData,
  WithRecordID,
} from "../../../models/shared/CommonModels";
import { AxiosError } from "axios";
import { fromAxiosErrorToMessage } from "../../../utils/crud";
import {
  fetchCustomerBankAccountsQueryKey,
  fetchSingleCustomerBankAccountQueryKey,
} from "../../../utils/QueryClient";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent";
import { REQUIRED_HELPER_TEXT } from "../../../utils/form";
import {
  BankAccount,
  BankAccountPostModel,
  BankAccountPutModel,
  BankAccountRequest,
} from "../../../models/api/bank-account/bank-account-model";
import {
  createBankAccount,
  editBankAccount,
  fetchBankAccount,
} from "../../../api/bank-account/bank-account-client";

const BankAccountForm: React.FC<WithCUToggleCommandsAndData<BankAccount>> = ({
  initialData,
  open,
  toggleOpen,
  editEnabled,
  onClose,
}) => {
  const queryClient = useQueryClient();

  const [hideError, setHideError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [bankName, setBankName] = useState<string>();
  const [iban, setIban] = useState<string>();
  const [swift, setSwift] = useState<string>();
  const [accountHolder, setAccountHolder] = useState<string>();
  const [preferred, setPreferred] = useState<boolean>(false);

  const query = useQuery({
    queryKey: [fetchSingleCustomerBankAccountQueryKey, initialData.id],
    queryFn: () => fetchBankAccount(initialData.id),
    enabled: !!initialData.id,
    onSuccess: (getResponse) => {
      setBankName(getResponse.data.bank_name);
      setIban(getResponse.data.iban);
      setSwift(getResponse.data.swift);
      setAccountHolder(getResponse.data.account_holder);
      setPreferred(getResponse.data.preferred);
    },
    refetchOnWindowFocus: false,
  });

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createBankAccount(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCustomerBankAccountsQueryKey);
      resetFields();
      toggleOpen();
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editBankAccount(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCustomerBankAccountsQueryKey);
      resetFields();
      toggleOpen();
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const resetFields = () => {
    // resetting fields
    setBankName("");
    setIban("");
    setSwift("");
    setAccountHolder("");
    setPreferred(false);
  };

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  const closeActions = () => {
    toggleOpen();
    if (!hideError) {
      queryClient.invalidateQueries(fetchCustomerBankAccountsQueryKey);
    }
  };

  const handleSubmit = () => {
    if (
      bankName === undefined ||
      bankName === "" ||
      iban === undefined ||
      iban === "" ||
      swift === undefined ||
      swift === "" ||
      accountHolder === undefined ||
      accountHolder === ""
    ) {
      setErrorMessage("Inserisci i campi obbligatori");
      setHideError(false);
      return;
    } else {
      setHideError(true);
    }

    if (editEnabled) {
      const template = {} as BankAccountPutModel;
      const data = {} as BankAccountRequest;
      const postData = {} as WithRecordID;
      template.bank_name = bankName;
      template.iban = iban;
      template.swift = swift;
      template.account_holder = accountHolder;
      template.preferred = preferred;

      data.bank_account = template;
      postData.data = data;
      postData.recordID = initialData.id;

      editMutation.mutate(postData);
    } else {
      const post = {} as BankAccountPostModel;
      post.id_customer = initialData.id_customer;
      post.bank_name = bankName;
      post.iban = iban;
      post.swift = swift;
      post.account_holder = accountHolder;
      post.preferred = preferred;
      const postData = {} as WithData;
      const body = {} as BankAccountRequest;
      body.bank_account = post;
      postData.data = body;

      createMutation.mutate(postData);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>
          {editEnabled ? "Modifica" : "Crea Nuovo"} Conto Bancario
        </DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => {
              setHideError(true);
            }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {REQUIRED_HELPER_TEXT}
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Banca"
            name="bank_name"
            size="small"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="IBAN"
            name="iban"
            size="small"
            value={iban}
            onChange={(e) => setIban(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="SWIFT"
            name="swift"
            size="small"
            value={swift}
            onChange={(e) => setSwift(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="Intestatario/a"
            name="account_holder"
            size="small"
            value={accountHolder}
            onChange={(e) => setAccountHolder(e.target.value)}
          />

          <Box display="flex" justifyContent="left">
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferred ? true : false}
                  onClick={() => setPreferred(!preferred)}
                />
              }
              label="Preferito"
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button
              onClick={() => {
                if (onClose !== undefined) {
                  onClose();
                }

                if (!editEnabled) {
                  resetFields();
                }
                closeActions();
              }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">
              {editEnabled ? "Salva" : "Aggiungi"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BankAccountForm;
