import { api_url } from "../utils/ApiContext";
import {
  FetchPaginatedApiModel,
  WithRecordID,
  WithData,
} from "../models/shared/CommonModels";
import { CredenzialiPortaleVenditaModel } from "../models/CredenzialiPortaleVenditaModel";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";

const TAKE_LIMIT = 15;

const fetchCredenzialiPortaleVenditaForBeneContratto = (
  id_bene_contratto: number
) => {
  return getRequest<CredenzialiPortaleVenditaModel[]>(
    api_url.concat(
      "/api/credenzialiportalevendita/bene_contratto/",
      id_bene_contratto.toString()
    )
  );
};

const fetchCustomerCredenzialiPortaleVendita = (
  page: number,
  id_cliente: number
) => {
  let skip = 0;
  let take = TAKE_LIMIT;
  if (page > 0) skip = page * 15;
  return getRequest<FetchPaginatedApiModel<CredenzialiPortaleVenditaModel[]>>(
    api_url.concat(
      "/api/credenzialiportalevendita?id_cliente=",
      id_cliente.toString(),
      "&skip=",
      skip.toString(),
      "&take=",
      take.toString()
    )
  );
};

const fetchCustomerCredenzialePortaleVendita = (id_cliente: number) => {
  return getRequest<CredenzialiPortaleVenditaModel>(
    api_url.concat("/api/credenzialiportalevendita/", id_cliente.toString())
  );
};

const createCredenzialiPortaleVendita = (postdata: WithData) => {
  return postRequest(
    api_url.concat("/api/credenzialiportalevendita"),
    postdata.data
  );
};

const editCredenzialiPortaleVendita = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat(
      "/api/credenzialiportalevendita/",
      putdata.recordID.toString()
    ),
    putdata.data
  );
};

const deleteCredenzialiPortaleVendita = (postdata: WithData) => {
  return deleteRequest(
    api_url.concat("/api/credenzialiportalevendita/", postdata.data as string)
  );
};

export {
  fetchCustomerCredenzialiPortaleVendita,
  fetchCustomerCredenzialePortaleVendita,
  createCredenzialiPortaleVendita,
  editCredenzialiPortaleVendita,
  deleteCredenzialiPortaleVendita,
  fetchCredenzialiPortaleVenditaForBeneContratto,
};
