import { GrafoRaggiungibilita } from "../models/BeneContrattoModel";
import { WithRecordID } from "../models/shared/CommonModels";
import { api_url } from "../utils/ApiContext";
import { getRequest, putRequest } from "./apiCommon";

const fetchGrafoRaggiungibilita = (id_bene: number) => {
  return getRequest<GrafoRaggiungibilita>(
    api_url.concat("/api/benecontratto/bene/", id_bene.toString())
  );
};

const editBeneContratto = (data: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/benecontratto/", data.recordID.toString()),
    data.data
  );
};

export { fetchGrafoRaggiungibilita, editBeneContratto };
