import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { NomeDescrizione } from "../../models/StatoTipologiaModel";
import {
  fetchNomeDescrizioneForEntity,
  tipologiaBeneUri,
} from "../../api/statotipologia";
import {
  FetchPaginatedApiModel,
  WithAnagraficaClienteID,
  WithFabObj,
  WithData,
  WithRowOperations,
} from "../../models/shared/CommonModels";
import {
  fetchBeniQueryKey,
  fetchTipologiaBeneSelectQueryKey,
} from "../../utils/QueryClient";
import { deleteBene, fetchSelectBeni } from "../../api/beni";
import { BeneModelDetailed } from "../../models/BeneModel";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import FilteredDataTable from "../shared/FilteredDataTable";
import { castToBackendSort } from "../../utils/sorting";
import { SortingModel } from "../../models/shared/SortingModel";
import DeleteDialog from "../shared/DeleteDialog";
import BeneFormComponent from "./BeneFormComponent";

interface BeneTableComponentProps extends WithAnagraficaClienteID, WithFabObj {
  dettaglio: boolean;
  enableCreate: boolean;
}

const BeneTableComponent: React.FC<BeneTableComponentProps> = ({
  anagrafica_cliente_id,
  fabVisible,
  alterFabVisibility,
  enableCreate,
}) => {
  const navigator = useNavigate();
  const queryClient = useQueryClient();

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [beni, setBeni] = useState<FetchPaginatedApiModel<BeneModelDetailed[]>>(
    undefined as any
  );
  const [selectedRowElement, setSelectedRowElement] =
    useState<BeneModelDetailed>();

  const [filters, setFilters] = useState<GridFilterModel | undefined>();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [tipologiaBeneRecords, setTipologiaBeneRecords] = useState<
    NomeDescrizione[]
  >([]);

  const [errorMessage, setErrorMessage] = useState("");
  const hideError = () => setErrorMessage("");

  // tipologia bene query
  useQuery({
    queryKey: [fetchTipologiaBeneSelectQueryKey],
    queryFn: () => {
      return fetchNomeDescrizioneForEntity(tipologiaBeneUri);
    },
    onSuccess: (getResponse) => {
      if (tipologiaBeneRecords.length <= 0) {
        setTipologiaBeneRecords(getResponse.data);
      }
    },
    refetchOnWindowFocus: false,
  });

  // query
  useQuery(
    [fetchBeniQueryKey, paginationModel.page, filters, sortBy, sortDirection],
    () => {
      const sor = {} as SortingModel;
      sor.field = sortBy || "id";
      sor.direction = sortDirection || "DESC";
      return fetchSelectBeni(
        paginationModel.page,
        filters,
        sor,
        anagrafica_cliente_id
      );
    },
    {
      keepPreviousData: true,
      onSuccess: (getResponse) => {
        setBeni(getResponse.data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteBene(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchBeniQueryKey);
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined)
        setErrorMessage(e.response.data.message);
      else
        setErrorMessage(
          "Errore non previsto, riprovare o richiedere assistenza"
        );
    },
  });

  const detailsOperations = (
    record: GridRenderCellParams<any, BeneModelDetailed>
  ) => {
    if (record.id !== undefined) {
      let model = (record as any).actions as BeneModelDetailed;
      navigator("/bene/".concat(model.id.toString()));
    }
  };

  const deleteOperations = (
    record: GridRenderCellParams<any, BeneModelDetailed>
  ) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Bene",
      flex: 1,
    },
    {
      field: "ubicazione",
      headerName: "Ubicazione",
      flex: 1,
    },
    {
      field: "tipologia_bene",
      headerName: "Categoria",
      sortable: false,
      flex: 1,
    },
    {
      field: "sotto_tipologia_bene",
      headerName: "Sotto-Categoria",
      sortable: false,
      flex: 1,
    },
    {
      field: "qualita_bene",
      headerName: "Stato",
      sortable: false,
      flex: 1,
    },
    {
      field: "brand_bene",
      headerName: "Brand",
      sortable: false,
      flex: 1,
    },
    {
      field: "bene_contratto_titolo",
      headerName: "Contratto",
      sortable: false,
      flex: 1,
    },
    {
      field: "bene_condizione_nome",
      headerName: "Condizione",
      sortable: false,
      flex: 1,
    },
    {
      field: "valutazione_min",
      headerName: "Valutazione Min",
      sortable: false,
      flex: 1,
    },
    {
      field: "valutazione_max",
      headerName: "Valutazione Max",
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <>
      <FilteredDataTable<BeneModelDetailed, WithRowOperations>
        loading={beni === undefined}
        detailsOperations={detailsOperations}
        // editOperations={editOperations}
        // editOperations={() => { }}
        deleteOperations={deleteOperations}
        rows={
          beni?.data !== undefined
            ? beni.data.map((d) => {
                return {
                  id: d.id,
                  ubicazione: d.ubicazione,
                  tipologia_bene: d.tipologiabene.nome,
                  sotto_tipologia_bene:
                    d.sottotipologiabene?.nome || "Non disponibile",
                  qualita_bene: d.qualitabene.nome,
                  brand_bene: d.brandbene?.nome || "Non disponibile",
                  bene_contratto_titolo:
                    d.benecontratto[0].contratto?.titolo || "Non associato",
                  bene_condizione_nome: d.benecontratto[0].condizione.nome,
                  valutazione_min: d.valutazione_minima + "€",
                  valutazione_max: d.valutazione_massima + "€",
                  actions: d,
                  delete_action: d,
                  hasDetails: true,
                  hasDelete: true,
                };
              })
            : []
        }
        rowCount={beni?.count}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        errorMessage={errorMessage}
        hideError={hideError}
        filters={filters}
        sortModel={sortModel}
        onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
        onSortChange={(newSortModel) => {
          newSortModel.forEach((item) => {
            if (item !== undefined) {
              setSortBy(item.field);
              setSortDirection(castToBackendSort(item.sort));
            }
          });

          setSortModel(newSortModel);
        }}
      />

      {/* Create */}
      {
        <BeneFormComponent
          initialData={
            { id_cliente: anagrafica_cliente_id } as BeneModelDetailed
          }
          open={enableCreate ? !fabVisible : false}
          toggleOpen={() => {}}
          onClose={() => alterFabVisibility(true)}
          beniPerCliente={
            anagrafica_cliente_id !== -1 ? beni?.count : undefined
          }
        />
      }

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.id}
        onPositive={() => {
          toggleDeleteAlert();
          const x = {} as WithData;
          x.data = selectedRowElement?.id;
          deleteMutation.mutate(x);
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default BeneTableComponent;
