import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  CustomerDocument,
  CustomerDocumentPostModel,
  CustomerDocumentPutModel,
  CustomerDocumentRequest,
} from "../../../models/api/customer/document/customer-document-model";
import {
  WithCUToggleCommandsAndData,
  WithData,
  WithRecordID,
} from "../../../models/shared/CommonModels";
import { AxiosError } from "axios";
import { fromAxiosErrorToMessage } from "../../../utils/crud";
import {
  fetchCustomerDocumentTypeSelectQueryKey,
  fetchCustomerDocumentsQueryKey,
  fetchSingleCustomerDocumentQueryKey,
} from "../../../utils/QueryClient";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {
  createCustomerDocument,
  editCustomerDocument,
  fetchCustomerDocument,
} from "../../../api/customer-document/customer-document-client";
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent";
import { REQUIRED_HELPER_TEXT } from "../../../utils/form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import StatoTipologiaSelectComponent from "../../shared/StatoTipologiaSelectComponent";
import { NomeDescrizione } from "../../../models/StatoTipologiaModel";
import { fetchCustomerDocumentTypes } from "../../../api/customer-document/customer-document-type-client";

const CustomerDocumentForm: React.FC<
  WithCUToggleCommandsAndData<CustomerDocument>
> = ({ initialData, open, toggleOpen, editEnabled, onClose }) => {
  const queryClient = useQueryClient();

  const [hideError, setHideError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [typeId, setTypeId] = useState<number>(0);
  const [documentNumber, setDocumentNumber] = useState<string>();
  const [releasedBy, setReleasedBy] = useState<string>();
  const [releasedAt, setReleasedAt] = useState<Dayjs | null>();
  const [expiresAt, setExpiresAt] = useState<Dayjs | null>();
  const [preferred, setPreferred] = useState<boolean>(false);

  const [documentTypeRecords, setDocumentTypeRecords] = useState<
    NomeDescrizione[]
  >([]);

  // customer document type query
  useQuery({
    queryKey: [fetchCustomerDocumentTypeSelectQueryKey],
    queryFn: () => {
      return fetchCustomerDocumentTypes();
    },
    onSuccess: (getResponse) => {
      if (documentTypeRecords.length <= 0) {
        setDocumentTypeRecords(
          getResponse.data.map((d) => {
            return {
              id: d.id,
              nome: d.name,
              descrizione: d.name,
            } as NomeDescrizione;
          })
        );
        if (getResponse.data.length > 0) {
          setTypeId(getResponse.data[0].id);
        }
      }
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const query = useQuery({
    queryKey: [fetchSingleCustomerDocumentQueryKey, initialData.id],
    queryFn: () => fetchCustomerDocument(initialData.id),
    enabled: !!initialData.id,
    onSuccess: (getResponse) => {
      setTypeId(getResponse.data.id_document_type);
      setDocumentNumber(getResponse.data.document_number);
      setReleasedBy(getResponse.data.released_by);
      setReleasedAt(dayjs(getResponse.data.released_at));
      setExpiresAt(dayjs(getResponse.data.expires_at));
      setPreferred(getResponse.data.preferred);
    },
    refetchOnWindowFocus: false,
  });

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createCustomerDocument(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCustomerDocumentsQueryKey);
      resetFields();
      toggleOpen();
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editCustomerDocument(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCustomerDocumentsQueryKey);
      resetFields();
      toggleOpen();
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const resetFields = () => {
    // resetting fields
    setTypeId(0);
    setDocumentNumber("");
    setReleasedBy("");
    setReleasedAt(null);
    setExpiresAt(null);
    setPreferred(false);
  };

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  const closeActions = () => {
    toggleOpen();
    if (!hideError) {
      queryClient.invalidateQueries(fetchCustomerDocumentsQueryKey);
    }
  };

  const handleSubmit = () => {
    if (
      typeId === undefined ||
      documentNumber === undefined ||
      documentNumber === "" ||
      releasedBy === undefined ||
      releasedBy === "" ||
      releasedAt === undefined ||
      expiresAt === undefined
    ) {
      setErrorMessage("Inserisci i campi obbligatori");
      setHideError(false);
      return;
    } else {
      setHideError(true);
    }

    if (expiresAt?.isBefore(releasedAt)) {
      setErrorMessage(
        "Data di rilascio deve essere precedente a data di scadenza"
      );
      setHideError(false);
      return;
    }

    if (editEnabled) {
      const template = {} as CustomerDocumentPutModel;
      const data = {} as CustomerDocumentRequest;
      const postData = {} as WithRecordID;
      template.document_number = documentNumber;
      template.released_by = releasedBy;
      template.expires_at = expiresAt!.format("YYYY-MM-DD");
      template.released_at = releasedAt!.format("YYYY-MM-DD");
      template.preferred = preferred;
      data.customer_document = template;
      postData.data = data;
      postData.recordID = initialData.id;

      editMutation.mutate(postData);
    } else {
      const post = {} as CustomerDocumentPostModel;
      post.id_customer = initialData.id_customer;
      post.id_document_type = typeId;
      post.document_number = documentNumber;
      post.released_by = releasedBy;
      post.preferred = preferred;
      post.expires_at = expiresAt!.format("YYYY-MM-DD");
      post.released_at = releasedAt!.format("YYYY-MM-DD");
      const postData = {} as WithData;
      const body = {} as CustomerDocumentRequest;
      body.customer_document = post;
      postData.data = body;

      createMutation.mutate(postData);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>
          {editEnabled ? "Modifica" : "Crea Nuovo"} Documento Cliente
        </DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => {
              setHideError(true);
            }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {REQUIRED_HELPER_TEXT}
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{ marginTop: "10pt", marginBottom: "5pt" }}
          >
            {documentTypeRecords.length > 0 ? (
              <StatoTipologiaSelectComponent
                initialId={initialData.id_document_type || 1}
                disabled={editEnabled || false}
                required={true}
                label="Tipo"
                records={documentTypeRecords}
                setId={setTypeId}
                // onChange={() => setHasConfirmedContractDocument(false)}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </Box>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Numero"
            name="number"
            size="small"
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="Rilasciato da"
            name="released_by"
            size="small"
            value={releasedBy}
            onChange={(e) => setReleasedBy(e.target.value)}
          />

          <Box
            sx={() => ({
              width: "100%",
              marginTop: "5pt",
              marginBottom: "10pt",
            })}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={"Rilasciato il " + "*"}
                  name="rilasciato"
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  value={releasedAt || null}
                  onChange={(newValue) => {
                    setReleasedAt(dayjs(newValue));
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box
            sx={() => ({
              width: "100%",
              marginTop: "5pt",
              marginBottom: "5pt",
            })}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={"Data di Scadenza " + "*"}
                  name="scadenza"
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  value={expiresAt || null}
                  onChange={(newValue) => {
                    setExpiresAt(dayjs(newValue));
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box display="flex" justifyContent="left">
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferred ? true : false}
                  onClick={() => setPreferred(!preferred)}
                />
              }
              label="Preferito"
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button
              onClick={() => {
                if (onClose !== undefined) {
                  onClose();
                }

                if (!editEnabled) {
                  resetFields();
                }
                closeActions();
              }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">
              {editEnabled ? "Salva" : "Aggiungi"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomerDocumentForm;
