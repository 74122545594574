import React, { useState } from "react";
import { SelectedIDEvent } from "../../models/shared/CommonModels";
import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ContrattoModel } from "../../models/ContrattoModel";

interface GrafoContrattoSelectComponentProps extends SelectedIDEvent {
  records: ContrattoModel[];
  disabled: boolean;
  label?: string;
}

const GrafoContrattoSelectComponent: React.FC<
  GrafoContrattoSelectComponentProps
> = ({ records, setId, initialId, disabled, label }) => {
  const [condizioneId, setCondizioneId] = useState<number>(() => {
    let index = 0;
    let stop = false;
    for (let i = 0; i < records.length && !stop; i++)
      if (records[i].id === initialId) {
        index = i;
        stop = true;
      }
    return index;
  });

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    let stop = false;
    for (let i = 0; i < records.length && !stop; i++) {
      if (records[i].id === +event.target.value) {
        setCondizioneId(i);
        setId(records[i].id);
        stop = !stop;
      }
    }
  };

  return (
    <>
      {records.length > 0 ? (
        <FormControl>
          <InputLabel>{label || "Seleziona"}</InputLabel>
          <Select
            value={records[condizioneId].id.toString()}
            size="small"
            fullWidth
            label={label || "Seleziona"}
            onChange={handleChange}
            disabled={disabled}
          >
            {records.map((record) => (
              <MenuItem key={record.id} value={record.id}>
                {record.titolo}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <p>Contratti non disponibili</p>
      )}
    </>
  );
};

export default GrafoContrattoSelectComponent;
