import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { NomeDescrizione } from "../../models/StatoTipologiaModel";
import { SelectedIDEvent } from "../../models/shared/CommonModels";

interface withStatoTipologiaRecord extends SelectedIDEvent {
  records: NomeDescrizione[];
  disabled: boolean;
  required?: boolean | false;
  label?: string | "Seleziona";
  onChange?: (changedItemId: number) => void;
}

const StatoTipologiaSelectComponent: React.FC<withStatoTipologiaRecord> = ({
  records,
  setId,
  initialId,
  disabled,
  required,
  label,
  onChange,
}) => {
  const [statoTipologiaID, setStatoTipologiaID] = React.useState<number>(() => {
    let index = 0;
    let stop = false;
    for (let i = 0; i < records.length && !stop; i++)
      if (records[i].id === initialId) {
        index = i;
        stop = true;
      }
    return index;
  });

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    let stop = false;
    for (let i = 0; i < records.length && !stop; i++) {
      if (records[i].id === +event.target.value) {
        setStatoTipologiaID(i);
        setId(records[i].id);
        stop = !stop;
        onChange && onChange(records[i].id);
      }
    }
  };

  return (
    <FormControl>
      {required ? (
        <InputLabel>{label} *</InputLabel>
      ) : (
        <InputLabel>{label}</InputLabel>
      )}
      <Select
        value={records[statoTipologiaID].id.toString()}
        label={label}
        fullWidth
        onChange={handleChange}
        disabled={disabled}
        size="small"
      >
        {records.map((record) => (
          <MenuItem key={record.id} value={record.id}>
            {record.nome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatoTipologiaSelectComponent;
