import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { REQUIRED_HELPER_TEXT } from "../../utils/form";
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent";
import {
  WithCUToggleCommandsAndData,
  WithData,
} from "../../models/shared/CommonModels";
import { useState } from "react";
import { sendTestEmail } from "../../api/configuration/configuration-email";
import { TestEmailRequest } from "../../models/api/configuration/configuration-email";
import { fromAxiosErrorToMessage } from "../../utils/crud";
import { LoadingButton } from "@mui/lab";

const TestEmailForm: React.FC<WithCUToggleCommandsAndData<string>> = ({
  onClose,
  initialData,
  open,
  toggleOpen,
}) => {
  const [toAddress, setToAddress] = useState<string>(initialData);

  const [hideError, setHideError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetFields = () => {
    setToAddress("");
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (toAddress === undefined || toAddress === "") {
      setErrorMessage("Inserisci i campi obbligatori");
      setHideError(false);
      setLoading(false);
      return;
    } else {
      setHideError(true);
    }

    try {
      const postData = {} as WithData;
      const body = {} as TestEmailRequest;
      body.to_address = toAddress;
      postData.data = body;
      await sendTestEmail(postData);
      toggleOpen();
    } catch (err) {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err as any));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => toggleOpen()}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>Invio Mail di Test</DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => {
              setHideError(true);
            }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {REQUIRED_HELPER_TEXT}
          </Typography>

          <TextField
            margin="normal"
            label="Indirizzo Email Destinatario"
            name="to_address"
            required
            fullWidth
            value={toAddress}
            onChange={(e) => {
              setToAddress(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button
              onClick={() => {
                if (onClose !== undefined) {
                  onClose();
                }
                toggleOpen();
                resetFields();
              }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <LoadingButton
              sx={{ marginLeft: "5pt" }}
              onClick={() => handleSubmit()}
              variant="contained"
              loading={loading}
            >
              Invia
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TestEmailForm;
