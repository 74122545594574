import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  FetchPaginatedApiModel,
  WithFabObj,
  WithRowOperations,
} from "../../../models/shared/CommonModels";
import { deleteColore, getBeneColori } from "../../../api/bene/colore";
import DeleteDialog from "../../shared/DeleteDialog";
import FilteredDataTable from "../../shared/FilteredDataTable";
import { fetchColoreBeneSelectQueryKey } from "../../../utils/QueryClient";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { BeneColoreModel } from "../../../models/api/response/bene/BeneColoreModel";
import { castToBackendSort } from "../../../utils/sorting";
import BeneColoreFormComponent from "./BeneColoreFormComponent";
import { SortingModel } from "../../../models/shared/SortingModel";

interface BeneColoreTableComponentProps extends WithFabObj {
  enableCreate: boolean;
}

const BeneColoreTableComponent: React.FC<BeneColoreTableComponentProps> = ({
  alterFabVisibility,
  fabVisible,
  enableCreate,
}) => {
  const queryClient = useQueryClient();

  const [editFormOpen, setEditFormOpen] = useState<boolean>(false);
  const toggleEditFormOpen = () => setEditFormOpen(!editFormOpen);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [colori, setColori] = useState<
    FetchPaginatedApiModel<BeneColoreModel[]>
  >(undefined as any);
  const [selectedRowElement, setSelectedRowElement] =
    useState<BeneColoreModel>();

  const [filters, setFilters] = useState<GridFilterModel | undefined>();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const hideError = () => setErrorMessage("");

  // query
  useQuery(
    [
      fetchColoreBeneSelectQueryKey,
      paginationModel.page,
      filters,
      sortBy,
      sortDirection,
    ],
    () => {
      const sor = {} as SortingModel;
      sor.field = sortBy || "id";
      sor.direction = sortDirection || "DESC";
      return getBeneColori(0, paginationModel.page, sor, filters);
    },
    {
      keepPreviousData: true,
      onSuccess: (getResponse) => {
        setColori(getResponse.data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteColore(id),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchColoreBeneSelectQueryKey);
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined)
        setErrorMessage(e.response.data.message);
      else
        setErrorMessage(
          "Errore non previsto, riprovare o richiedere assistenza"
        );
    },
  });

  const editOperations = (
    record: GridRenderCellParams<any, BeneColoreModel>
  ) => {
    setSelectedRowElement(record.value);
    toggleEditFormOpen();
  };

  const deleteOperations = (
    record: GridRenderCellParams<any, BeneColoreModel>
  ) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Colore",
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
  ];

  return (
    <>
      <FilteredDataTable<BeneColoreModel, WithRowOperations>
        loading={colori === undefined}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
        rows={
          colori?.data !== undefined
            ? colori.data.map((d) => {
                return {
                  id: d.id,
                  nome: d.nome,
                  actions: d,
                  delete_action: d,
                  hasEdit: true,
                  hasDelete: true,
                };
              })
            : []
        }
        rowCount={colori?.count}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        errorMessage={errorMessage}
        hideError={hideError}
        filters={filters}
        sortModel={sortModel}
        onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
        onSortChange={(newSortModel) => {
          newSortModel.forEach((item) => {
            if (item !== undefined) {
              setSortBy(item.field);
              setSortDirection(castToBackendSort(item.sort));
            }
          });

          setSortModel(newSortModel);
        }}
      />

      {/* Create */}
      <BeneColoreFormComponent
        initialData={{} as BeneColoreModel}
        open={enableCreate ? !fabVisible : false}
        toggleOpen={() => {}}
        onClose={() => alterFabVisibility(true)}
      />

      {/* Edit */}
      {selectedRowElement !== undefined && (
        <BeneColoreFormComponent
          initialData={selectedRowElement}
          open={editFormOpen}
          toggleOpen={() => {
            toggleEditFormOpen();
          }}
          onClose={() => {
            setSelectedRowElement(undefined);
            toggleEditFormOpen();
          }}
          editEnabled={true}
        />
      )}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.id}
        onPositive={() => {
          toggleDeleteAlert();
          if (selectedRowElement?.id) {
            deleteMutation.mutate(selectedRowElement.id);
          }
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default BeneColoreTableComponent;
