import { ConfigurationEmail } from "../../models/api/configuration/configuration-email";
import { WithData } from "../../models/shared/CommonModels";
import { api_url } from "../../utils/ApiContext";
import { getRequest, postRequest } from "../apiCommon";

const getEmailConf = async () => {
  return getRequest<ConfigurationEmail>(
    api_url.concat("/api/configuration-email")
  );
};

const updateEmailConf = async (emailConfData: WithData) => {
  return postRequest(
    api_url.concat("/api/configuration-email"),
    emailConfData.data
  );
};

const sendTestEmail = async (testEmailData: WithData) => {
  return postRequest(
    api_url.concat("/api/configuration-email/send-test"),
    testEmailData.data
  );
};

export { getEmailConf, updateEmailConf, sendTestEmail };
