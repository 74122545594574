import { Autocomplete, TextField } from "@mui/material";
import React from "react";

interface SearchSelectProps<T> {
  options: T[];
  label: string;
  disabled?: boolean;
  selectedOptionId?: number;
  helperText?: string;
  required?: boolean;
  labelPresentation: (option: T) => string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: T | null
  ) => void;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => void;
}

const SearchSelect = <T,>({
  options,
  disabled,
  label,
  selectedOptionId,
  helperText,
  required,
  labelPresentation,
  onChange,
  onInputChange,
}: SearchSelectProps<T>) => {
  const [value, setValue] = React.useState<T | null>(
    options.find((op) => (op as any).id == selectedOptionId) || null
  );

  return (
    <>
      <Autocomplete
        disablePortal
        disableClearable={required}
        id="searchable-combo-box"
        size="small"
        disabled={disabled}
        value={value}
        fullWidth
        onChange={(event, newValue) => {
          onChange(event, newValue);
          setValue(newValue);
        }}
        autoComplete
        includeInputInList
        filterSelectedOptions
        filterOptions={(x) => x}
        options={options}
        getOptionLabel={labelPresentation}
        noOptionsText="Nessun dato disponibile"
        onInputChange={onInputChange}
        renderInput={(params: any) => (
          <TextField
            required={required || false}
            helperText={helperText || ""}
            fullWidth
            {...params}
            label={label}
          />
        )}
      />
    </>
  );
};

export default SearchSelect;
