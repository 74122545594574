import { Box, Tooltip, IconButton } from "@mui/material";
import { DataGrid, itIT, GridRenderCellParams } from "@mui/x-data-grid";
import { WithSimpleDataTableProps } from "../../models/shared/CommonModels";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SimpleDataTable = <T extends {}>({
  columns,
  rows,
  rowCount,
  detailsOperations,
  editOperations,
  deleteOperations,
}: WithSimpleDataTableProps<T>) => {
  return (
    <>
      <Box>
        <DataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          onCellDoubleClick={(params) => {
            if (detailsOperations !== undefined) {
              detailsOperations(params.row);
            }
          }}
          rows={rows}
          rowCount={rowCount}
          columns={[
            {
              field: "delete_action",
              // flex: 1,
              maxWidth: 10,
              headerName: "",
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (record: GridRenderCellParams<any, T>) => (
                <>
                  {deleteOperations !== undefined ? (
                    <Tooltip title="Cancella">
                      <IconButton
                        className="action_icon"
                        onClick={() => {
                          deleteOperations(record);
                        }}
                      >
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
            ...columns,
            {
              field: "actions",
              // flex: 1,
              // minWidth: 200,
              headerName: "Azioni",
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (record: GridRenderCellParams<any, T>) => (
                <>
                  {detailsOperations !== undefined ? (
                    <Tooltip title="Dettagli">
                      <IconButton
                        className="action_icon"
                        onClick={() => {
                          detailsOperations(record.row);
                        }}
                      >
                        <VisibilityIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}

                  {editOperations !== undefined ? (
                    <Tooltip title="Modifica">
                      <IconButton
                        className="action_icon"
                        onClick={() => {
                          editOperations(record);
                        }}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
          ]}
          autoHeight={true}
          hideFooter={true}
          hideFooterPagination={true}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100, // TODO: maybe generalize
              },
            },
          }}
          disableRowSelectionOnClick
          slotProps={{
            filterPanel: {
              filterFormProps: {
                operatorInputProps: {
                  disabled: true,
                },
              },
            },
          }}
        />
      </Box>
    </>
  );
};

export default SimpleDataTable;
