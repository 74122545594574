import { PersonaFisicaModel } from "../models/PersonaFisicaModel";
import { api_url } from "../utils/ApiContext";
import {
  FetchPaginatedApiModel,
  WithRecordID,
  WithData,
} from "../models/shared/CommonModels";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";
import { SortingModel } from "../models/shared/SortingModel";
import { GridFilterModel } from "@mui/x-data-grid";
import { appendStringFilterToUri } from "../utils/filtering";
import uniquePFMapFromArrays from "../utils/map";

const TAKE_LIMIT = 15;

const fetchPFsBySearchKey = async (searchKey: string | undefined) => {
  let baseUrl = api_url.concat("/api/personafisica?");
  let take = TAKE_LIMIT;

  let defaultUri = baseUrl.concat("skip=0").concat("&take=", take.toString());
  let nomeUri = baseUrl
    .concat("nome=", searchKey || "")
    .concat("&skip=0")
    .concat("&take=", take.toString());
  let cognomeUri = baseUrl
    .concat("cognome=", searchKey || "")
    .concat("&skip=0")
    .concat("&take=", take.toString());
  let codiceFiscaleUri = baseUrl
    .concat("codice_fiscale=", searchKey || "")
    .concat("&skip=0")
    .concat("&take=", take.toString());

  if (searchKey === undefined || searchKey === "") {
    return (
      await getRequest<FetchPaginatedApiModel<PersonaFisicaModel[]>>(defaultUri)
    ).data.data;
  }

  let [nomeData, cognomeData, codiceFiscaleData] = await Promise.all([
    getRequest<FetchPaginatedApiModel<PersonaFisicaModel[]>>(nomeUri),
    getRequest<FetchPaginatedApiModel<PersonaFisicaModel[]>>(cognomeUri),
    getRequest<FetchPaginatedApiModel<PersonaFisicaModel[]>>(codiceFiscaleUri),
    getRequest<FetchPaginatedApiModel<PersonaFisicaModel[]>>(nomeUri),
  ]);

  return Array.from(
    uniquePFMapFromArrays([
      nomeData.data.data,
      cognomeData.data.data,
      codiceFiscaleData.data.data,
    ]).values()
  );
};

const setQueryParameters = (
  filter: GridFilterModel | undefined,
  sort: SortingModel
) => {
  let uri = api_url.concat("/api/personafisica?");

  filter?.items.forEach((item) => {
    uri = appendStringFilterToUri(item.field, "nome", item.value, uri);
    uri = appendStringFilterToUri(item.field, "cognome", item.value, uri);
    uri = appendStringFilterToUri(
      item.field,
      "codice_fiscale",
      item.value,
      uri
    );
    uri = appendStringFilterToUri(
      item.field,
      "genesi_cliente",
      item.value,
      uri
    );
    uri = appendStringFilterToUri(item.field, "data_nascita", item.value, uri);
  });

  uri = uri.concat("&sort_field=", sort.field.toString());
  uri = uri.concat("&sort_direction=", sort.direction.toString());

  uri = uri.endsWith("?") ? uri.concat("skip=") : uri.concat("&skip=");

  return uri;
};

const fetchSelectAllPF = (
  page: number,
  filter: GridFilterModel | undefined,
  sort: SortingModel
) => {
  let uri = setQueryParameters(filter, sort);
  return fetchAllPF(page, uri);
};

const fetchAllPF = (
  page: number,
  uri: string = api_url.concat("/api/personafisica?skip=")
) => {
  let skip = 0;
  let take = TAKE_LIMIT;
  if (page > 0) skip = page * TAKE_LIMIT;
  return getRequest<FetchPaginatedApiModel<PersonaFisicaModel[]>>(
    uri.concat(skip.toString(), "&take=", take.toString())
  );
};

const createPF = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/personafisica"), postdata.data);
};

const fetchPF = (getData: WithData) => {
  return getRequest<PersonaFisicaModel>(
    api_url.concat("/api/personafisica/", getData.data as string)
  );
};

const deletePF = (postdata: WithData) => {
  return deleteRequest(
    api_url.concat("/api/personafisica/", postdata.data as string)
  );
};

const editPF = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/personafisica/", putdata.recordID.toString()),
    putdata.data
  );
};

export {
  fetchAllPF,
  createPF,
  fetchPF,
  deletePF,
  editPF,
  fetchSelectAllPF,
  fetchPFsBySearchKey,
};
