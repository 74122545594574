export const enum Pages {
  ANY = "*",
  ROOT = "/",
  LOGIN = "/login",
  HOME = "/home",
  BENE = "/bene",
  BENE_ID = "/bene/:id",
  BENE_BRAND = "/bene/brand",
  BENE_COLLEZIONE = "/bene/collezione",
  BENE_QUALITA = "/bene/stato",
  BENE_MATERIALE = "/bene/materiale",
  BENE_TIPOLOGIA = "/bene/tipologia",
  BENE_TIPOLOGIA_ID = "/bene/tipologia/:id",
  BENE_COLORE = "/bene/colore",
  PERSONA_FISICA = "/personafisica",
  PERSONA_FISICA_ID = "/personafisica/:idpefidana",
  PERSONA_GIURIDICA = "/personagiuridica",
  PERSONA_GIURIDICA_ID = "/personagiuridica/:idpefidana",
  CONTRATTO = "/contratto",
  CONTRATTO_ID = "/contratto/:id",
  CONFIGURATION = "/configuration",
}
