import { api_url } from "../../utils/ApiContext";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../apiCommon";
import { CustomerDocument } from "../../models/api/customer/document/customer-document-model";
import { WithData, WithRecordID } from "../../models/shared/CommonModels";

const apiPrefix: string = "/api/customer-document";

const fetchCustomerDocuments = (idCliente: number) => {
  return getRequest<CustomerDocument[]>(
    api_url.concat(apiPrefix + "?id_customer=", idCliente.toString())
  );
};

const fetchCustomerDocument = (id: number) => {
  return getRequest<CustomerDocument>(
    api_url.concat(apiPrefix + "/", id.toString())
  );
};

const createCustomerDocument = (postData: WithData) => {
  return postRequest(api_url.concat(apiPrefix), postData.data);
};

const deleteCustomerDocument = (postData: WithData) => {
  return deleteRequest(
    api_url.concat(apiPrefix + "/", postData.data as string)
  );
};

const editCustomerDocument = (putData: WithRecordID) => {
  return putRequest(
    api_url.concat(apiPrefix + "/", putData.recordID.toString()),
    putData.data
  );
};

export {
  fetchCustomerDocuments,
  fetchCustomerDocument,
  createCustomerDocument,
  editCustomerDocument,
  deleteCustomerDocument,
};
