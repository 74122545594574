import React, { useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ContractDocumentModel } from "../../models/ContrattoModel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import base64ToArrayBuffer from "../../utils/bytes";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { WithToggleAndReadonlyAndData } from "../../models/shared/CommonModels";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";

const ContractDocumentPreview: React.FC<
  WithToggleAndReadonlyAndData<ContractDocumentModel>
> = ({
  initialData,
  open,
  toggleOpen,
  readonly,
  handleSubmit,
  handleClose,
}) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [completedLoading, setCompletedLoading] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const pdfDocument = useMemo(
    () => base64ToArrayBuffer(initialData.base64ContractPdf),
    [initialData.base64ContractPdf]
  );

  const handleClosing = () => {
    setPageNumber(1);
    if (handleClose !== undefined) handleClose();
    toggleOpen();
  };

  const downloadBase64Pdf = (base64Data: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = fileName || "download.pdf";
    link.click();
  };

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
        maxWidth="md"
      >
        <DialogTitle>
          Anteprima Contratto
          {numPages > 1 && (
            <>
              : {pageNumber} / {numPages}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Document
            file={pdfDocument}
            onLoadSuccess={(pdfInfo) => {
              setNumPages(pdfInfo.numPages);
              setCompletedLoading(true);
            }}
            loading={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
            error={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            <Page
              pageNumber={pageNumber}
              className={"contractDocumentPage"}
              renderAnnotationLayer={false}
              renderTextLayer={true}
              // loading={
              //   <Box sx={{ display: "flex" }}>
              //     <CircularProgress />
              //   </Box>
              // }
            />
          </Document>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            {completedLoading && (
              <Button
                onClick={() => {
                  toggleOpen();
                  if (handleSubmit !== undefined) handleSubmit();
                }}
              >
                Chiudi
              </Button>
            )}
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            {numPages > 1 && (
              <>
                {pageNumber !== 1 ? (
                  <Button
                    sx={{ marginLeft: "5pt" }}
                    onClick={() => goToPrevPage()}
                    variant="contained"
                    color="secondary"
                  >
                    {"<"}
                  </Button>
                ) : (
                  <></>
                )}
                {pageNumber < numPages ? (
                  <Button
                    sx={{ marginLeft: "5pt" }}
                    onClick={() => goToNextPage()}
                    variant="contained"
                    color="secondary"
                  >
                    {">"}
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
            {completedLoading ? (
              <Tooltip title="Scarica">
                <LoadingButton
                  sx={{ marginLeft: "5pt" }}
                  disabled={loadingDownload}
                  loading={loadingDownload}
                  color="secondary"
                  variant="outlined"
                  className="action_icon"
                  startIcon={<FileDownloadIcon color="secondary" />}
                  onClick={() => {
                    setLoadingDownload(true);
                    downloadBase64Pdf(
                      initialData.base64ContractPdf,
                      "contratto_preview.pdf"
                    );
                    setLoadingDownload(false);
                  }}
                >
                  Salva
                </LoadingButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractDocumentPreview;
