import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Email } from "../../models/EmailModel";
import { deleteEmail, fetchCustomerEmails } from "../../api/emails";
import { Skeleton } from "@mui/material";
import "./CustomerDetails.scss";
import {
  WithData,
  WithAnagraficaClienteID,
} from "../../models/shared/CommonModels";
import { fetchEmailsQueryKey } from "../../utils/QueryClient";
import SimpleDataTable from "../shared/SimpleDataTable";
import DeleteDialog from "../shared/DeleteDialog";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomerEmailForm from "./extra/CustomerEmailForm";

interface WithEmailRecord extends WithAnagraficaClienteID {
  createFormOpen: boolean;
  toggleCreateFormOpen: () => void;
}

const CustomerEmailsTable: React.FC<WithEmailRecord> = ({
  anagrafica_cliente_id,
  createFormOpen,
  toggleCreateFormOpen,
}) => {
  const queryClient = useQueryClient();

  const [emails, setEmails] = useState<Email[]>(undefined as any);
  const [selectedRowElement, setSelectedRowElement] = useState<Email>();

  const [emailDataEditFormOpen, setEmailDataEditFormOpen] =
    useState<boolean>(false);
  const toggleEmailDataEditFormOpen = () =>
    setEmailDataEditFormOpen(!emailDataEditFormOpen);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteEmail(x),
    onSuccess: () => {
      setSelectedRowElement(undefined);
      queryClient.invalidateQueries(fetchEmailsQueryKey);
    },
    onError: () => {},
  });

  const query = useQuery({
    queryKey: [fetchEmailsQueryKey],
    queryFn: () => fetchCustomerEmails(anagrafica_cliente_id),
    onSuccess: (getResponse) => {
      setEmails(getResponse.data);
    },
    refetchOnWindowFocus: false,
  });

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  // TODO: verify if (query.isError) return (<Navigate to="/home" replace />)

  const editOperations = (record: GridRenderCellParams<any, Email>) => {
    setSelectedRowElement(record.value);
    toggleEmailDataEditFormOpen();
  };

  const deleteOperations = (record: GridRenderCellParams<any, Email>) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  return (
    <>
      <SimpleDataTable<Email>
        columns={[
          {
            field: "email",
            headerName: "Email",
            flex: 1,
          },
          {
            field: "pec",
            headerName: "PEC",
            type: "boolean",
            sortable: false,
            flex: 1,
          },
          {
            field: "preferred",
            headerName: "Preferito",
            type: "boolean",
            sortable: false,
            flex: 1,
          },
        ]}
        rows={
          emails !== undefined
            ? emails.map((d) => {
                return {
                  id: d.id,
                  email: d.email,
                  pec: d.pec,
                  preferred: d.preferred,
                  actions: d,
                  delete_action: d,
                } as unknown as Email;
              })
            : []
        }
        rowCount={emails.length}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
      />

      {/* Add Email */}
      <CustomerEmailForm
        initialData={{ id_cliente: anagrafica_cliente_id } as Email}
        open={createFormOpen}
        toggleOpen={toggleCreateFormOpen}
      />

      {/* Edit Email */}
      {selectedRowElement && (
        <CustomerEmailForm
          initialData={selectedRowElement}
          open={emailDataEditFormOpen}
          toggleOpen={toggleEmailDataEditFormOpen}
          editEnabled={true}
        />
      )}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.email}
        onPositive={() => {
          toggleDeleteAlert();
          const x = {} as WithData;
          x.data = selectedRowElement?.id;
          deleteMutation.mutate(x);
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default CustomerEmailsTable;
