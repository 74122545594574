import { BeneContrattoPortaleDetailedModel } from "../models/BeneContrattoPortaleModel";
import {
  FetchPaginatedApiModel,
  WithRecordID,
  WithData,
} from "../models/shared/CommonModels";
import { api_url } from "../utils/ApiContext";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";

const fetchPaginatedBeneContrattoPortale = (
  page: number,
  id_bene_contratto: number,
  uri: string = api_url.concat("/api/benecontrattoportale?skip=")
) => {
  let skip = 0;
  let take = "15";
  if (page > 1) skip = (page - 1) * 15;
  return getRequest<
    FetchPaginatedApiModel<BeneContrattoPortaleDetailedModel[]>
  >(
    uri.concat(
      skip.toString(),
      "&take=",
      take.toString(),
      "&id_bene_contratto=",
      id_bene_contratto.toString()
    )
  );
};

const createBeneContrattoPortale = (postdata: WithData) => {
  return postRequest(
    api_url.concat("/api/benecontrattoportale"),
    postdata.data
  );
};

const editBeneContrattoPortale = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/benecontrattoportale/", putdata.recordID.toString()),
    putdata.data
  );
};

const deleteBeneContrattoPortale = (postdata: WithData) => {
  return deleteRequest(
    api_url.concat("/api/benecontrattoportale/", postdata.data as string)
  );
};

export {
  createBeneContrattoPortale,
  fetchPaginatedBeneContrattoPortale,
  editBeneContrattoPortale,
  deleteBeneContrattoPortale,
};
