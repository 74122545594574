import { axiosClient } from "./client";

const postRequest = (uri: string, model: any) => {
  return axiosClient.post(uri, model, {
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });
};

const deleteRequest = (uri: string) => {
  return axiosClient.delete(uri, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });
};

const getRequest = <T>(uri: string) => {
  return axiosClient.get<T>(uri, {
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });
};

const putRequest = (uri: string, model: any) => {
  return axiosClient.put(uri, model, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });
};

export { postRequest, deleteRequest, getRequest, putRequest };
