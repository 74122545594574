import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {
  WithCUToggleCommandsAndData,
  WithRecordID,
  WithData,
} from "../../models/shared/CommonModels";
import {
  ContrattoPostPutModel,
  ContrattoRequest,
  ContrattoTableModel,
} from "../../models/ContrattoModel";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createContratto, editContratto } from "../../api/contratto";
import {
  fetchPFsQByKeyQueryKey,
  fetchCommissioniQueryKey,
  fetchStatoContrattoSelectQueryKey,
  fetchTipologiaContrattoSelectQueryKey,
  fetchContrattoQueryKey,
  fetchCustomerDocumentsQueryKey,
  fetchCustomerBankAccountsQueryKey,
  fetchPFQueryKey,
} from "../../utils/QueryClient";
import { REQUIRED_HELPER_TEXT } from "../../utils/form";
import dayjs, { Dayjs } from "dayjs";
import { NomeDescrizione } from "../../models/StatoTipologiaModel";
import { PersonaFisicaModel } from "../../models/PersonaFisicaModel";
import SearchSelect from "../shared/SearchSelect";
import { fetchPF, fetchPFsBySearchKey } from "../../api/personafisica";
import { useDebounce } from "@uidotdev/usehooks";
import {
  fetchNomeDescrizioneForEntity,
  statoContrattoUri,
  tipologiaContrattoUri,
} from "../../api/statotipologia";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import "dayjs/locale/it";
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent";
import { AxiosError, AxiosResponse } from "axios";
import { fromAxiosErrorToMessage } from "../../utils/crud";
import ContractDocumentPreview from "./ContractDocumentPreview";
import { generateContractPreview } from "../../api/contract/document";
import { fetchCustomerDocuments } from "../../api/customer-document/customer-document-client";
import { fetchBankAccounts } from "../../api/bank-account/bank-account-client";
import { CustomerDocument } from "../../models/api/customer/document/customer-document-model";
import { BankAccount } from "../../models/api/bank-account/bank-account-model";
import AlertDialog from "../shared/AlertDialog";
import SplitButton from "../shared/SplitButton";

const ContrattoFormComponent: React.FC<
  WithCUToggleCommandsAndData<ContrattoTableModel>
> = ({ editEnabled, onClose, initialData, open, toggleOpen }) => {
  const queryClient = useQueryClient();

  const [documentPreviewOpen, setDocumentPreviewOpen] = useState(false);
  const toggleDocumentPreviewOpen = () =>
    setDocumentPreviewOpen(!documentPreviewOpen);
  const [base64DocumentPreview, setBase64DocumentPreview] =
    useState<string>("");
  const [hasConfirmedContractDocument, setHasConfirmedContractDocument] =
    useState(false);

  const [hideError, setHideError] = useState<boolean>(true);
  const [confirmationAlertOpen, setConfirmationAlertOpen] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [clienti, setClienti] = useState<PersonaFisicaModel[]>([]);
  const [searchClienti, setSearchClienti] = useState<string>();
  const deferredSearchClienti = useDebounce(searchClienti, 1000);
  const [selectedClienteId, setSelectedClienteId] = useState<
    number | undefined
  >(initialData.id_cliente);
  const [selectedCliente, setSelectedCliente] = useState<
    PersonaFisicaModel | undefined
  >();

  const [customerDocuments, setCustomerDocuments] = useState<
    CustomerDocument[]
  >([]);

  // TODO: verify
  const [searchCustomerDocuments, setSearchCustomerDocuments] =
    useState<string>();
  const deferredSelectCustomerDocuments = useDebounce(
    searchCustomerDocuments,
    1000
  );
  const [selectedCustomerDocumentId, setSelectedCustomerDocumentId] = useState<
    number | undefined
  >(initialData.id_customer_document);

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [searchBankAccounts, setSearchBankAccounts] = useState<string>();
  const deferredSearchBankAccounts = useDebounce(searchBankAccounts, 1000);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<
    number | undefined
  >(initialData.id_bank_account);

  const [loading, setLoading] = useState<boolean>(false);

  const [statoContrattoRecords, setStatoContrattoRecords] = useState<
    NomeDescrizione[]
  >([]);
  const [tipologiaContrattoRecords, setTipologiaContrattoRecords] = useState<
    NomeDescrizione[]
  >([]);

  const [note, setNote] = useState(initialData.note || "");
  // const [quantita, setQuantita] = useState<number | string>(
  //   initialData.quantita
  // );
  // const [importo, setImporto] = useState<number | string>(initialData.importo);
  const [selectedStato, setSelectedStato] = useState(
    initialData.id_stato_contratto || 0
  );
  const [selectedTipologia, setSelectedTipologia] = useState(
    initialData.id_tipologia_contratto || 1
  );
  const [dataScadenza, setDataScadenza] = useState<Dayjs | null>(
    dayjs(initialData.data_scadenza) || null
  );

  const resetFields = () => {
    // reset fields for double insertions
    setSelectedClienteId(undefined);

    if (!editEnabled) {
      setSearchClienti(" ");
    }

    setNote("");
    // setQuantita(0);
    // setImporto(0);
    setSelectedStato(0);
    setSelectedTipologia(1);
    setDataScadenza(null);
    setBase64DocumentPreview("");
    setHasConfirmedContractDocument(false);
    setSelectedCustomerDocumentId(undefined);
    setSelectedBankAccountId(undefined);
  };

  // stato contratto query
  useQuery({
    queryKey: [fetchStatoContrattoSelectQueryKey],
    queryFn: () => {
      if (editEnabled) {
        return fetchNomeDescrizioneForEntity(
          statoContrattoUri,
          false,
          false,
          initialData?.id
        );
      }

      return fetchNomeDescrizioneForEntity(statoContrattoUri, true, false, -1);
    },
    onSuccess: (getResponse) => {
      if (statoContrattoRecords.length <= 0) {
        setStatoContrattoRecords(getResponse.data);
        if (getResponse.data.length > 0) {
          setSelectedStato(getResponse.data[0].id);
        }
      }
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  // tipologia contratto query
  useQuery({
    queryKey: [fetchTipologiaContrattoSelectQueryKey],
    queryFn: () => fetchNomeDescrizioneForEntity(tipologiaContrattoUri),
    onSuccess: (getResponse) => {
      if (tipologiaContrattoRecords.length <= 0) {
        setTipologiaContrattoRecords(getResponse.data);
        if (getResponse.data.length > 0) {
          // by design because the first one must always have id 1
          setSelectedTipologia(getResponse.data[1].id);
        }
      }
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  // search customer if present
  useQuery({
    // queryKey: [fetchPFQueryKey],
    queryFn: () => fetchPF({ data: initialData.id_cliente }),
    onSuccess: (response: AxiosResponse<PersonaFisicaModel, any>) => {
      setSelectedCliente(response.data);
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
    enabled: !editEnabled && initialData.id_cliente !== undefined,
  });

  // search clienti query
  useQuery({
    queryKey: [fetchPFsQByKeyQueryKey, deferredSearchClienti],
    queryFn: () => fetchPFsBySearchKey(searchClienti),
    onSuccess: (response: PersonaFisicaModel[]) => {
      if (response.length > 0) {
        setClienti(response);
      } else {
        setClienti([]);
      }
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  // fetch customer documents query
  useQuery({
    queryKey: [fetchCustomerDocumentsQueryKey, selectedClienteId],
    queryFn: () => fetchCustomerDocuments(selectedClienteId!),
    onSuccess: (getResponse) => {
      setCustomerDocuments(getResponse.data);
      if (
        getResponse.data.length > 0 &&
        selectedCustomerDocumentId === undefined
      ) {
        setSelectedCustomerDocumentId(getResponse.data[0].id);
      }
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
    enabled: selectedClienteId !== undefined && selectedClienteId !== -1,
  });

  // fetch bank accounts query
  useQuery({
    queryKey: [fetchCustomerBankAccountsQueryKey, selectedClienteId],
    queryFn: () => fetchBankAccounts(selectedClienteId!),
    onSuccess: (getResponse) => {
      setBankAccounts(getResponse.data);
      if (getResponse.data.length > 0 && selectedBankAccountId === undefined) {
        setSelectedBankAccountId(getResponse.data[0].id);
      }
    },
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
    enabled: selectedClienteId !== undefined && selectedClienteId !== -1,
  });

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createContratto(x),
    onSuccess: () => {
      setLoading(false);
      queryClient.invalidateQueries(fetchCommissioniQueryKey);
      // queryClient.invalidateQueries(fetchPFsQByKeyQueryKey)
      resetFields();
      if (onClose !== undefined) onClose();
      toggleOpen();
    },
    onError: (err: AxiosError) => {
      setLoading(false);
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editContratto(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCommissioniQueryKey);
      queryClient.invalidateQueries(fetchContrattoQueryKey);
      toggleOpen();
      setLoading(false);
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
      setLoading(false);
    },
  });

  const closeActions = () => {
    toggleOpen();
    if (!hideError) {
      queryClient.invalidateQueries(fetchContrattoQueryKey);
    }
  };

  const prepareContractCreate = (mustSendViaEmail: boolean) => {
    const contractData = {} as WithData;
    const contratto = {} as ContrattoPostPutModel;

    if (selectedClienteId !== undefined) {
      contratto.id_cliente = selectedClienteId;
    }

    contratto.id_stato_contratto = selectedStato;
    contratto.id_tipologia_contratto = selectedTipologia;
    contratto.note = note;

    contratto.id_customer_document = selectedCustomerDocumentId;
    contratto.id_bank_account = selectedBankAccountId;

    if (dataScadenza) {
      contratto.data_scadenza = dataScadenza.format("YYYY-MM-DD");
    }

    if (mustSendViaEmail) {
      contratto.must_send_via_email = mustSendViaEmail;
    }

    const postData = {} as ContrattoRequest;
    postData.contratto = contratto;
    contractData.data = postData;

    return contractData;
  };

  const handleDocumentContractPreview = async () => {
    if (selectedClienteId === -1) {
      setHideError(false);
      setErrorMessage("Inserire il cliente");
      return;
    } else if (selectedCustomerDocumentId === undefined) {
      setHideError(false);
      setErrorMessage("Inserire il documento cliente");
      return;
    } else {
      setHideError(true);
      setErrorMessage("");
    }

    setBase64DocumentPreview("");
    toggleDocumentPreviewOpen();

    try {
      let contractPreviewRes = await generateContractPreview(
        prepareContractCreate(false)
      );

      setBase64DocumentPreview(contractPreviewRes.data);
    } catch (err: any) {
      setDocumentPreviewOpen(false);
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err as AxiosError));
      setLoading(false);
    }
  };

  const toggleConfirmationMessage = () => {
    setConfirmationAlertOpen(true);
  };

  const handleSubmit = (mustSendViaEmail: boolean = false) => {
    setLoading(true);
    if (editEnabled) {
      if (initialData === undefined) {
        return;
      }

      const putModel = {} as ContrattoPostPutModel;
      putModel.note = note;
      // putModel.quantita = Math.floor(Number(quantita)) || 0;
      // putModel.importo = Number(importo) || 0;
      putModel.id_stato_contratto = selectedStato;
      putModel.id_tipologia_contratto = initialData.id_tipologia_contratto;
      putModel.id_cliente = initialData.id_cliente;

      putModel.id_customer_document = selectedCustomerDocumentId;
      putModel.id_bank_account = selectedBankAccountId;

      if (dataScadenza) {
        putModel.data_scadenza = dataScadenza.format("YYYY-MM-DD");
      }

      const putData = {} as WithRecordID;
      const putContratto = {} as ContrattoRequest;
      putContratto.contratto = putModel;
      putData.recordID = initialData.id;
      putData.data = putContratto;

      editMutation.mutate(putData);
    } else {
      if (!hasConfirmedContractDocument) {
        setHideError(false);
        setErrorMessage(
          "Bisogna visionare l'anteprima prima di creare il contratto"
        );
        return;
      }

      createMutation.mutate(prepareContractCreate(mustSendViaEmail));
    }
  };

  return (
    <>
      {/* Confirmation */}
      <AlertDialog
        open={confirmationAlertOpen}
        onClose={() => setConfirmationAlertOpen(false)}
        customMessage={`Sicuri di voler creare il contratto per ${
          selectedCliente?.nome
        } ${selectedCliente?.cognome} e inviarlo a ${
          (selectedCliente?.anagraficacliente as any)?.emails?.find(
            (m: { preferred: number }) => m.preferred === 1
          )?.email
        } ?`}
        onPositive={() => {
          setConfirmationAlertOpen(false);
          handleSubmit(true);
        }}
        onNegative={() => setConfirmationAlertOpen(false)}
      />

      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>
          {editEnabled ? "Modifica" : "Crea Nuovo"} Contratto
        </DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => {
              setHideError(true);
            }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {REQUIRED_HELPER_TEXT}
          </Typography>

          {/** TODO: verify if we need to re enable them later */}
          {/* <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            label="Quantità"
            name="quantita"
            size="small"
            value={quantita}
            onChange={e => setQuantita(e.target.value || "")}
          /> */}

          {/* <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            label="Importo"
            name="importo"
            size="small"
            value={importo}
            onChange={e => { setImporto(e.target.value || "") }}
          /> */}

          <Box
            sx={() => ({
              width: "100%",
              marginTop: "5pt",
              // marginBottom: "5pt",
            })}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Data di Scadenza"
                  name="scadenza"
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  value={dataScadenza || null}
                  onChange={(newValue) => {
                    setHasConfirmedContractDocument(false);
                    setDataScadenza(dayjs(newValue));
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ marginTop: "10pt" }}
          >
            {statoContrattoRecords.length > 0 ? (
              <SearchSelect
                options={statoContrattoRecords}
                disabled={false}
                selectedOptionId={initialData.id_stato_contratto || 0}
                label={"Stato Contratto"}
                required={true}
                labelPresentation={(option: NomeDescrizione) => {
                  return `${option.nome}`;
                }}
                onChange={(event, newInputValue: NomeDescrizione | null) => {
                  setSelectedStato(newInputValue?.id!);
                }}
                onInputChange={(event, newInputValue: string) => {
                  setHasConfirmedContractDocument(false);
                }}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ marginTop: "10pt" }}
          >
            {tipologiaContrattoRecords.length > 0 ? (
              <SearchSelect
                options={tipologiaContrattoRecords.reduce(
                  (acc: NomeDescrizione[], item) => {
                    if (item.id === 1) {
                      acc.unshift(item);
                    } else {
                      acc.push(item);
                    }
                    return acc;
                  },
                  []
                )}
                disabled={editEnabled || false}
                selectedOptionId={initialData.id_tipologia_contratto || 1}
                label={"Tipologia Contratto"}
                required={true}
                labelPresentation={(option: NomeDescrizione) => {
                  return `${option.nome}`;
                }}
                onChange={(event, newInputValue: NomeDescrizione | null) => {
                  setSelectedTipologia(newInputValue?.id!);
                }}
                onInputChange={(event, newInputValue: string) => {
                  setHasConfirmedContractDocument(false);
                }}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </Box>

          {!editEnabled && initialData.id_cliente === -1 && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                sx={{ marginTop: "10pt" }}
              >
                <SearchSelect
                  options={clienti}
                  label={"Cliente Associato"}
                  required={true}
                  labelPresentation={(option: PersonaFisicaModel) => {
                    if (option.codice_fiscale !== null) {
                      return `${option.nome} ${option.cognome} - ${
                        option.codice_fiscale || ""
                      }`;
                    } else {
                      return `${option.nome} ${option.cognome}`;
                    }
                  }}
                  onChange={(
                    event,
                    newInputValue: PersonaFisicaModel | null
                  ) => {
                    setHasConfirmedContractDocument(false);
                    setSelectedClienteId(newInputValue?.id_cliente);
                    setSelectedCliente(newInputValue!);

                    setSelectedCustomerDocumentId(undefined);
                    setCustomerDocuments([]);

                    setSelectedBankAccountId(undefined);
                    setBankAccounts([]);
                  }}
                  onInputChange={(event, newInputValue: string) => {
                    setSearchClienti(newInputValue);
                  }}
                />
              </Box>
            </>
          )}

          {/* Documents */}
          {selectedClienteId !== -1 && customerDocuments.length > 0 ? (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ marginTop: "10pt" }}
            >
              <SearchSelect
                options={customerDocuments}
                disabled={editEnabled || false}
                selectedOptionId={selectedCustomerDocumentId}
                label={"Documento"}
                required={true}
                labelPresentation={(option: CustomerDocument) => {
                  // TODO: also document type ?
                  return `${option.document_number} ${option.released_by}`;
                }}
                onChange={(event, newInputValue: CustomerDocument | null) => {
                  setSelectedCustomerDocumentId(newInputValue?.id);
                }}
                onInputChange={(event, newInputValue: string) => {
                  setHasConfirmedContractDocument(false);
                }}
              />
            </Box>
          ) : (
            <></>
          )}

          {/* Bank Accounts */}
          {selectedClienteId !== -1 && bankAccounts.length > 0 ? (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ marginTop: "10pt" }}
            >
              <SearchSelect
                options={bankAccounts}
                disabled={editEnabled || false}
                selectedOptionId={selectedBankAccountId}
                label={"Conto"}
                required={false}
                labelPresentation={(option: BankAccount) => {
                  return `${option.bank_name} ${option.iban}`;
                }}
                onChange={(event, newInputValue: BankAccount | null) => {
                  setSelectedBankAccountId(newInputValue?.id);
                }}
                onInputChange={(event, newInputValue: string) => {
                  setHasConfirmedContractDocument(false);
                }}
              />
            </Box>
          ) : (
            <></>
          )}

          <TextField
            margin="normal"
            label="Note"
            name="note"
            multiline
            fullWidth
            rows={4}
            value={note}
            onChange={(e) => {
              setHasConfirmedContractDocument(false);
              setNote(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button
              onClick={() => {
                if (onClose !== undefined) {
                  onClose();
                }

                if (!editEnabled) {
                  resetFields();
                }

                closeActions();
              }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            {!editEnabled ? (
              <Button
                onClick={() => handleDocumentContractPreview()}
                variant="contained"
              >
                Preview
              </Button>
            ) : (
              <></>
            )}

            {hasConfirmedContractDocument ? (
              <SplitButton
                options={["Crea", "Crea e Invia"]}
                loading={loading}
                onButtonClick={(selectedOption) => {
                  if (selectedOption === "Crea") {
                    handleSubmit(false);
                  } else {
                    toggleConfirmationMessage();
                  }
                }}
              />
            ) : (
              <></>
            )}

            {editEnabled ? (
              <Button
                sx={{ marginLeft: "5pt" }}
                onClick={() => handleSubmit()}
                variant="contained"
              >
                Salva
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <ContractDocumentPreview
        initialData={{ base64ContractPdf: base64DocumentPreview }}
        open={documentPreviewOpen}
        toggleOpen={toggleDocumentPreviewOpen}
        handleSubmit={() => setHasConfirmedContractDocument(true)}
        handleClose={() => setHasConfirmedContractDocument(false)}
      />
    </>
  );
};

export default ContrattoFormComponent;
