import { Telefono } from "../models/TelefonoModel";
import { api_url } from "../utils/ApiContext";
import { WithRecordID, WithData } from "../models/shared/CommonModels";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./apiCommon";

const fetchCustomerTelefoni = (id_cliente: number) => {
  return getRequest<Telefono[]>(
    api_url.concat("/api/telefonocliente?id_cliente=", id_cliente.toString())
  );
};

const fetchTelefono = (id: number) => {
  return getRequest<Telefono>(
    api_url.concat("/api/telefonocliente/", id.toString())
  );
};

const createTelefono = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/telefonocliente"), postdata.data);
};

const deleteTelefono = (postdata: WithData) => {
  return deleteRequest(
    api_url.concat("/api/telefonocliente/", postdata.data as string)
  );
};

const editTelefono = (putdata: WithRecordID) => {
  return putRequest(
    api_url.concat("/api/telefonocliente/", putdata.recordID.toString()),
    putdata.data
  );
};

export {
  fetchCustomerTelefoni,
  fetchTelefono,
  createTelefono,
  deleteTelefono,
  editTelefono,
};
