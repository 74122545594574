import { useState } from "react";
import {
  BeneColoreModel,
  BeneColorePostModel,
  BeneColorePutModel,
  BeneColoreRequest,
} from "../../../models/api/response/bene/BeneColoreModel";
import {
  WithCUToggleCommandsAndData,
  WithData,
  WithRecordID,
} from "../../../models/shared/CommonModels";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import {
  fromAxiosErrorToMessage,
  prepareCreateData,
  prepareEditData,
} from "../../../utils/crud";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent";
import { REQUIRED_HELPER_TEXT } from "../../../utils/form";
import { createColoreBene, updateColore } from "../../../api/bene/colore";
import { fetchColoreBeneSelectQueryKey } from "../../../utils/QueryClient";

const BeneColoreFormComponent: React.FC<
  WithCUToggleCommandsAndData<BeneColoreModel>
> = ({ editEnabled, onClose, initialData, open, toggleOpen }) => {
  const queryClient = useQueryClient();

  const [hideError, setHideError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [nome, setNome] = useState<string>(initialData.nome || "");

  const [error, setError] = useState<boolean>(false);

  const resetFields = () => {
    setNome("");
    setHideError(true);
  };

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createColoreBene(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchColoreBeneSelectQueryKey);
      resetFields();
      if (onClose !== undefined) onClose();
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => updateColore(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchColoreBeneSelectQueryKey);
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const closeActions = () => {
    toggleOpen();
    if (!hideError) {
      queryClient.invalidateQueries(fetchColoreBeneSelectQueryKey);
    }
  };

  const handleSubmit = () => {
    if (editEnabled) {
      if (initialData === undefined) {
        return;
      }

      const benePut = {} as BeneColorePutModel;
      benePut.nome = nome;

      const request = {} as BeneColoreRequest;
      request.colore_bene = benePut;

      editMutation.mutate(prepareEditData(initialData.id, request));
    } else {
      const benePost = {} as BeneColorePostModel;
      benePost.nome = nome;

      const request = {} as BeneColoreRequest;
      request.colore_bene = benePost;

      createMutation.mutate(prepareCreateData(request));
    }

    toggleOpen();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>
          {editEnabled ? "Modifica" : "Crea Nuovo"} Colore Bene
        </DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => {
              setHideError(true);
            }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {REQUIRED_HELPER_TEXT}
          </Typography>

          <TextField
            error={error ? error : undefined}
            margin="normal"
            required={true}
            label="Nome"
            name="nome"
            multiline
            fullWidth
            value={nome}
            onChange={(e) => {
              setError(false);
              setNome(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button
              onClick={() => {
                if (onClose !== undefined) {
                  onClose();
                }

                if (!editEnabled) {
                  resetFields();
                }

                closeActions();
              }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">
              {editEnabled ? "Salva" : "Crea"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BeneColoreFormComponent;
